import React from "react";
import ReactDOM from "react-dom/client";
import "@/styles/global.css";
import App from "@/client/App";
import { BrowserRouter } from "react-router-dom";
import "@/styles/index.css";
import "altcha";

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
