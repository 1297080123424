import React, { Fragment } from "react";
import AssetDashboard from "./components/assetDashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./chartSetup";

/**
 * Hosts the React SPA.
 * @returns SPA root node
 */
function App(): JSX.Element {
  return (
    <Fragment>
      <AssetDashboard />
      <ToastContainer />
    </Fragment>
  );
}

export default App;
