import React from "react";
import { toast } from "react-toastify";

import "./assetDashboard.css";

import DescriptionPanel from "./descriptionPanel";
import LookupSymbol from "../lookupSymbol";
import IssueHistoryLoader from "@/services/issueHistoryLoader";
import IssueHistory from "@/entities/issueHistory";
import { IndicatorStrategy } from "@/entities/indicatorStrategy.ts";
import { ChartOption, ChartTabs, IndicatorPeriod, IndicatorPeriods } from "./assetDashboard.types";
import PageTabBar, { PageTabBarTab } from "./pageTabBar";
import ChartPanel, { getChartOptionsFor, getDefaultChartOptions } from "./charts/chartPanel";
import ModelIndicator from "@/services/modelIndicator";
import DetailPanel from "./details/detailPanel";
import OptionPicker, { OptionsActive } from "./optionPicker/optionPicker";
import { getDefaultCapitalGrowthOptions } from "./charts/capitalGrowthChart";
import LandingPanel from "./landingPanel";
import { ModelIndicatorBuilder } from "@/services/modelIndicatorBuilder.ts";
import ModelReportBuilder from "@/services/modelReportBuilder";
import ModelReport from "@/services/modelReport";
import { loginService } from "@/client/loginSerivce";

// TODO Cleanup needed, maybe move to someplace more global, and/or unhook when we're done
// TODO We could also consider making the web view fixed size to match the print needs,
//    although we might still need this to force resize on print view anyway.
// TODO Could also consider using react-to-print
// Might all be obsolete if/when we move this feature to the cloud and use a PDF generator.
import { Chart } from "chart.js";
function printHandler(ev: Event, before: boolean) {
  for (const id in Chart.instances) {
    const chart = Chart.instances[id];
    const chartParent = chart.canvas.parentElement;
    if (chartParent) {
      if (before) {
        chartParent.style.width = "160mm";
        const printWidth = chartParent.offsetWidth;
        const printHeight = chartParent.offsetHeight;
        chart.resize(printWidth, printHeight);
      } else {
        chartParent.style.width = "";
        chart.resize();
      }
    }
  }
}
window.addEventListener("beforeprint", (ev) => printHandler(ev, true));
window.addEventListener("afterprint", (ev) => printHandler(ev, false));

/**
 * Implements Asset Dashboard screen
 * @returns Asset Dashboard DOM
 */
export default function AssetDashboard(): React.ReactElement {
  const [strategy, setStrategy] = React.useState<IndicatorStrategy>(IndicatorStrategy.ThreeDay);
  const [period, setPeriod] = React.useState<IndicatorPeriod>(IndicatorPeriods[0]);
  const [selectedTab, setSelectedTab] = React.useState(ChartTabs[0]);
  const [displayIssue, setDisplayIssue] = React.useState<IssueHistory | null>(null);
  const [indicators, setIndicators] = React.useState<Array<ModelIndicator>>();
  const [optionActive, setOptionActive] = React.useState(OptionsActive.none);
  const [chartOptions, setChartOptions] = React.useState<ChartOption[]>(getDefaultChartOptions());
  const [modelReport, setModelReport] = React.useState<ModelReport | null>(null);

  const showChartOptions = React.useMemo<ChartOption[]>(() => {
    return getChartOptionsFor(selectedTab, chartOptions);
  }, [selectedTab, chartOptions]);

  function updateReportOptions(
    Strategy: IndicatorStrategy,
    Indicators: ModelIndicator[],
    Period: IndicatorPeriod
  ) {
    setStrategy(Strategy);
    setIndicators(Indicators);
    setPeriod(Period);

    generateReport(Indicators, Strategy, Period);
  }

  function generateReport(
    indicators: ModelIndicator[] | undefined,
    strategy: IndicatorStrategy,
    period: IndicatorPeriod
  ) {
    if (indicators && period) {
      const indicator =
        indicators.find((indicator) => indicator.period === strategy) ?? indicators[0];
      const report = ModelReportBuilder.build(indicator, period);
      setModelReport(report);
    }
  }
  React.useEffect(() => {
    generateReport(indicators, strategy, period);
  }, [indicators, period, strategy]);

  const handleSymbolLookup = (issue: IssueHistory, indicators: ModelIndicator[]): void => {
    setDisplayIssue(issue);
    updateReportOptions(strategy, indicators, period);
    updateSelectedTab(ChartTabs[0]);
    updateChartOptions(getDefaultCapitalGrowthOptions());
  };

  const lookupSymbol = (symbol: string): void => {
    const toastId = toast.loading("Looking up issue...");
    IssueHistoryLoader.loadIssue(symbol).then((loadResult) => {
      if (typeof loadResult === "string") {
        toast.update(toastId, {
          render: loadResult,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else if ((loadResult.daily?.length ?? 0) < IndicatorPeriod.MIN_TRADING_DATES) {
        // Too few trading days, reject
        const msg = `${loadResult.symbol} has too few trading days for reporting.`;
        toast.update(toastId, {
          render: msg,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        const loadedIssue: IssueHistory = loadResult;
        // const daily = loadedIssue.daily!;
        toast.dismiss(toastId);
        setDisplayIssue(loadedIssue);
        updateReportOptions(
          strategy,
          [
            new ModelIndicatorBuilder(loadedIssue, IndicatorStrategy.Daily).build(),
            new ModelIndicatorBuilder(loadedIssue, IndicatorStrategy.ThreeDay).build(),
            new ModelIndicatorBuilder(loadedIssue, IndicatorStrategy.Weekly).build(),
            new ModelIndicatorBuilder(loadedIssue, IndicatorStrategy.Monthly).build(),
            new ModelIndicatorBuilder(loadedIssue, IndicatorStrategy.ThreeMonth).build(),
          ],
          period
        );
        updateSelectedTab(ChartTabs[0]);
        updateChartOptions(getDefaultCapitalGrowthOptions());
      }
    });
  };

  const updateSelectedTab = (tab: PageTabBarTab) => {
    setSelectedTab(tab);
    setOptionActive(OptionsActive.none);
  };

  const updateChartOptions = (updates: ChartOption[]) => {
    const finalOptions = chartOptions.map((orgOpt) => {
      const updOpt = updates.find((findOpt) => findOpt.id === orgOpt.id);
      return updOpt ?? orgOpt;
    });
    setChartOptions(finalOptions);
  };

  const showDetails = selectedTab.tabId !== "print";
  const showDescription =
    showDetails && (chartOptions.find((opt) => opt.id === "comments")?.isEnabled ?? true);

  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
        display: "flex",
        flexDirection: "column",
        padding: "5pt",
      }}
    >
      {displayIssue ? (
        <>
          <div className="asset-dashboard-head" style={{ gap: 0 }}>
            <div>
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <LookupSymbol onSymbol={lookupSymbol} />
              </div>
              <div>
                <PageTabBar
                  tabs={ChartTabs}
                  currentTab={selectedTab}
                  onSelect={updateSelectedTab}
                />
              </div>
            </div>
            <div
              className="random-div"
              style={{ width: "10pt", borderBottom: "1px solid transparent" }}
            />
            <OptionPicker
              tab={selectedTab}
              strategy={strategy}
              period={period}
              chartOptions={showChartOptions}
              optionsActive={optionActive}
              setOptionActive={setOptionActive}
              setStrategy={setStrategy}
              setPeriod={setPeriod}
              setChartOption={updateChartOptions}
            />
          </div>

          <div className="asset-dashboard-cont">
            <div className="cont-main">
              <div className="cont-graph">
                <ChartPanel
                  tab={selectedTab}
                  period={period}
                  displayIssue={displayIssue}
                  strategy={strategy}
                  indicators={indicators}
                  chartOptions={showChartOptions}
                  setChartOption={updateChartOptions}
                  report={modelReport}
                />
              </div>
              {showDescription && (
                <div className="cont-descrip">
                  <DescriptionPanel
                    tab={selectedTab}
                    strategy={strategy}
                    period={period}
                    issue={displayIssue}
                    onChange={(Strategy, Period) => {
                      updateReportOptions(Strategy, indicators!, Period);
                    }}
                  />
                </div>
              )}
            </div>
            {showDetails && (
              <div className="cont-detail">
                <DetailPanel
                  tab={selectedTab}
                  issue={displayIssue}
                  strategy={strategy}
                  indicators={indicators}
                  period={period}
                  report={modelReport}
                />
              </div>
            )}
          </div>

          <div className="asset-dashboard-foot">
            <p>© {new Date().getFullYear()} Drivers Limited</p>
            <p className="footer-links">
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                LinkedIn
              </a>{" "}
              |{" "}
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                X
              </a>
            </p>
            <button className="logoutButton" onClick={() => loginService.logout()}>
              Logout
            </button>
          </div>
        </>
      ) : (
        <LandingPanel onSymbolLookup={handleSymbolLookup} />
      )}
    </div>
  );
}
