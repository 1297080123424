import React from "react";

import "./detailPanel.css";

import { PageTabBarTab } from "../pageTabBar";
import IssueHistory from "@/entities/issueHistory";
import ModelIndicator from "@/services/modelIndicator";
import CapitalGrowthDetails from "./capitalGrowthDetails";
import CapitalExposureDetails from "./capitalExposureDetails";
import { IndicatorPeriod } from "../assetDashboard.types";
import MarketPerformanceDetails from "./marketPerformanceDetails";
import RiskPerformanceDetails from "./riskPerformanceDetails";
import MarketSentimentDetails from "./marketSentimentDetails";
import { IndicatorStrategy } from "@/entities/indicatorStrategy.ts";
import ModelReport from "@/services/modelReport";

type DetailPanelProps = {
  tab: PageTabBarTab;
  issue?: IssueHistory;
  strategy: IndicatorStrategy;
  indicators?: ModelIndicator[];
  period: IndicatorPeriod;
  report: ModelReport | null;
};

/**
 * Provides the detail panel at the right of the content area.
 *
 * @returns Panel element tree
 */
export default function DetailPanel(props: DetailPanelProps): React.ReactElement {
  const details = detailsFor(props);
  if (!details) {
    // No issue loaded
    return <div className="asset-dashboard-detail no-issue">Details for {props.tab.display}</div>;
  }

  return (
    <div className="asset-dashboard-detail">
      <div className="detail-name">
        {props.issue!.name}
        <span style={{ fontSize: "50%" }}>
          <br />
          Symbol: {props.issue!.symbol}
        </span>
      </div>
      <div className="detail-contents">{detailsFor(props)}</div>
    </div>
  );
}

function detailsFor(props: DetailPanelProps): React.ReactNode {
  // Verify that we have an issue and indicator to display
  const indicator = props.indicators?.find((test) => test.period === props.strategy);
  if (!props.issue || !indicator || !props.report) {
    return false;
  }

  // Return the correct details component for the current tab
  const { tabId } = props.tab;
  if (tabId === "capgro") {
    // Current position details
    return (
      <CapitalGrowthDetails
        issue={props.issue}
        indicator={indicator}
        period={props.period}
        report={props.report}
      />
    );
  }
  if (tabId === "capexp") {
    // Capital exposure details
    return (
      <CapitalExposureDetails
        issue={props.issue}
        indicator={indicator}
        period={props.period}
        report={props.report}
      />
    );
  }
  if (tabId === "marprf") {
    // Market performance details
    return (
      <MarketPerformanceDetails
        issue={props.issue}
        indicator={indicator}
        period={props.period}
        report={props.report}
      />
    );
  }
  if (tabId === "rskprf") {
    // Risk performance details
    return (
      <RiskPerformanceDetails
        issue={props.issue}
        indicator={indicator}
        period={props.period}
        report={props.report}
      />
    );
  }
  if (tabId === "marsen") {
    // Market sentiment details
    return <MarketSentimentDetails />;
  }

  /* TODO Implement the remaining types.
  {tabId: "marsen", display: "Market Sentiment"},
  {tabId: "allcrt", display: "All Charts"},
  */

  // Show placeholder
  return <div>Details for {props.tab.display}</div>;
}
