import ModelIndicator from "@/services/modelIndicator";
import DateUtils from "@/services/dateUtils";
import { PageTabBarTab } from "./pageTabBar";

export type ReportDates = {
  startDate: Date;
  endDate: Date;
  groupByMonths: number;
};

// TODO Move this to its own module
export class IndicatorPeriod {
  static MIN_TRADING_DATES = 30;

  readonly description: string;
  readonly months: number;

  constructor(description: string, months: number) {
    this.description = description;
    this.months = months;
  }

  /**
   * Gets the end date for the period based on the raw data in the passed indicator, inclusive.
   * @param indicator Indicator provides details to determine end date
   * @return End date for period, at midnight, inclusive
   */
  getDates(indicator: ModelIndicator): ReportDates {
    // Calculate edge cases
    let groupByMonths = 1;
    let startDate = DateUtils.today(indicator.closeDate.at(0) ?? new Date());
    let endDate = DateUtils.today(indicator.closeDate.at(-1) ?? new Date());
    const lastDay = new Date(endDate);
    const dateRangeDay = indicator.calendarDays;
    const dateRangeYears = dateRangeDay / 365;
    console.assert(
      dateRangeDay >= IndicatorPeriod.MIN_TRADING_DATES,
      `${dateRangeDay} is too few trading days for reporting.`
    );
    if (dateRangeYears >= 2 && (this.months === 0 || this.months >= 60)) {
      // End at last complete year
      groupByMonths = 12;
      endDate = DateUtils.lastCompleteYear(lastDay);
      const delYears = Math.floor(this.months / 12);
      let startYear = !!delYears ? endDate.getUTCFullYear() - delYears + 1 : 0;
      if (startYear < startDate.getUTCFullYear()) {
        // Adjust for available data
        startYear =
          startDate.getUTCMonth() === 0 && startDate.getUTCDate() === 1
            ? startDate.getUTCFullYear()
            : startDate.getUTCFullYear() + 1;
      }
      startDate = new Date(Date.UTC(startYear, 0, 1));
    } else if (dateRangeYears >= 2 && this.months >= 24) {
      // 2 years uses quarterly data
      groupByMonths = 3;
      endDate = DateUtils.lastCompleteYear(lastDay);
      const delMonths = Math.floor(this.months / 3) * 3;
      const fullStartDate = new Date(endDate);
      fullStartDate.setUTCDate(fullStartDate.getUTCDate() + 1);
      fullStartDate.setUTCMonth(fullStartDate.getUTCMonth() - delMonths);
      if (fullStartDate >= startDate) {
        // Plenty of data use calculated start
        startDate = fullStartDate;
      } else if (startDate.getUTCDate() !== 1 || startDate.getUTCMonth() % 3 !== 0) {
        // Start at next full quarter
        startDate.setUTCDate(1);
        startDate.setUTCMonth((Math.floor(startDate.getUTCMonth() / 3) + 1) * 3);
      }
    } else if (dateRangeYears >= 0.5 && (this.months === 0 || this.months >= 12)) {
      // 1 years monthly data
      endDate = DateUtils.lastCompleteYear(lastDay);
      const fullStartDate = new Date(endDate);
      const periodMonths = !!this.months ? this.months : 12;
      fullStartDate.setUTCDate(1);
      fullStartDate.setUTCMonth(endDate.getUTCMonth() - periodMonths + 1);
      if (fullStartDate >= startDate) {
        // Plenty of data used calculated start
        startDate = fullStartDate;
      } else if (startDate.getUTCDate() !== 1) {
        // Start at next full month
        startDate.setUTCDate(1);
        startDate.setUTCMonth(startDate.getUTCMonth() + 1);
      }
    } else {
      // Fewer than 12 months show YTD
      endDate = DateUtils.today(lastDay);
      const fullStartDate = new Date(endDate);
      fullStartDate.setUTCMonth(endDate.getUTCMonth() - this.months + 1);
      if (fullStartDate >= startDate) {
        startDate = fullStartDate;
      }
    }

    // Return the reporting dates
    return { startDate, endDate, groupByMonths };
  }
}

export const IndicatorPeriods: IndicatorPeriod[] = [
  new IndicatorPeriod("All Data", 0),
  new IndicatorPeriod("5 Years", 60),
  new IndicatorPeriod("2 Years", 24),
  new IndicatorPeriod("1 Year", 12),
  new IndicatorPeriod("6 Months", 6),
];

export const ChartTabs: PageTabBarTab[] = [
  { tabId: "capgro", display: "Capital Growth" },
  { tabId: "capexp", display: "Capital Exposure" },
  { tabId: "marprf", display: "Market Performance" },
  { tabId: "rskprf", display: "Risk Performance" },
  { tabId: "marsen", display: "Market Sentiment" },
  // { tabId: "print", display: "Report" }, Commented out for now, this maybe something we add back later for certain users such as Ste.
];

export type ChartOption = {
  chart: string;
  id: string;
  name: string;
  isEnabled: boolean;
  radioGroup?: string;
};
