export default class DateUtils {
  static readonly MSEC_DAY = 86400000;
  static readonly MSEC_FIRST_SUNDAY = DateUtils.MSEC_DAY * 3;
  static readonly MSEC_WEEK = DateUtils.MSEC_DAY * 7;
  static readonly EPOCH_MON_OFFSET_DAYS = 3;

  static calcWeek(date: Date): number {
    return Math.floor((date.getTime() - DateUtils.MSEC_FIRST_SUNDAY) / DateUtils.MSEC_WEEK);
  }

  static dateFromWeek(week: number): Date {
    return new Date(week * DateUtils.MSEC_WEEK + DateUtils.MSEC_FIRST_SUNDAY);
  }

  /**
   * Gets the beginning of the date (e.g. midnight).
   * @param fromDate Optional date to use, otherwise current date
   * @returns Time stamp for the top of the day
   */
  static today(fromDate?: Date): Date {
    const epoch = fromDate?.getTime() ?? Date.now();
    return new Date(Math.floor(epoch / DateUtils.MSEC_DAY) * DateUtils.MSEC_DAY);
  }

  /**
   * Gets the first day of the month minus the month delta
   * @param delta Number of months to go back
   * @returns Calculated date from now
   */
  static startOfMonth(delta: number, fromDate?: Date): Date {
    const res = DateUtils.today(fromDate);
    res.setUTCDate(1);

    let dYear = res.getUTCFullYear() - Math.floor(delta / 12);
    let dMonth = res.getUTCMonth() - (delta % 12) + 1;
    if (dMonth < 0) {
      --dYear;
      dMonth += 12;
    }
    res.setUTCFullYear(dYear);
    res.setUTCMonth(dMonth);
    return res;
  }

  /**
   * Gets the first day of the year minus the delta. To go back to the
   * beginning of the current year, delta = 1. Delta of zero goes to
   * the start of the next year.
   *
   * @param delta Number of years to go back
   * @param fromDate Optional use this date instead of today
   * @returns Calculated date from now (or optional passed date)
   */
  static startOfYear(delta: number, fromDate?: Date): Date {
    const res = DateUtils.today(fromDate);
    res.setUTCDate(1);
    res.setUTCMonth(0);
    if (delta > 1) {
      res.setUTCFullYear(res.getUTCFullYear() - delta + 1);
    }
    return res;
  }

  /**
   * Gets the start date of a reporting period based on today.
   * @deprecated Old logic is too simplistic
   * @param showMonths Number of months in period
   * @returns Report start date
   */
  static showFromDate(showMonths: number): Date | undefined {
    if (showMonths >= 12) {
      return DateUtils.startOfYear(showMonths / 12);
    }
    if (showMonths > 0) {
      return DateUtils.startOfMonth(showMonths);
    }
    return undefined;
  }

  /**
   * Calculates the number of days between two days, inclusive.
   * @param from From (early) date
   * @param to To (late) date
   * @returns Number of full days
   */
  static daysDiff(from: Date, to: Date): number {
    const fromDays = Math.floor(from.getTime() / this.MSEC_DAY);
    const toDays = Math.floor(to.getTime() / this.MSEC_DAY);
    return toDays - fromDays + 1;
  }

  static monthsDiff(from: Date, to: Date): number {
    return (to.getFullYear() - from.getFullYear()) * 12 + (to.getMonth() - from.getMonth());
  }

  private static dtfMedium = Intl.DateTimeFormat("en", { dateStyle: "medium" });

  static formatShortMonth(from: Date): string {
    return this.dtfMedium.formatToParts(from).find((i) => i.type === "month")?.value ?? "???";
  }

  /**
   * Tests if a date is on the last day of its month.
   * @param fromDate Date to test
   * @returns Indicates if the date is the last day if its month
   */
  static isLastDayOfMonth(fromDate: Date): boolean {
    const testDate = new Date(fromDate);
    testDate.setUTCDate(testDate.getUTCDate() + 1);
    return testDate.getUTCMonth() !== fromDate.getUTCMonth();
  }

  /**
   * Gets the last complete month end date.
   * @returns End of the last complete month
   */
  static lastCompleteMonth(): Date {
    const today = DateUtils.today();
    today.setUTCDate(0);
    return today;
  }

  /**
   * Gets the last complete quarter end date from the optional reference date,
   * or today if not provided.
   * @param fromDate Optional reference date
   * @returns End of the last complete quarter
   */
  static lastCompleteQuarter(fromDate?: Date): Date {
    const useDate = fromDate ?? new Date();
    const useMonth = Math.floor(useDate.getUTCMonth() / 3) * 3;
    if (useMonth + 2 !== useDate.getUTCMonth() || !this.isLastDayOfMonth(useDate)) {
      // Go back to the end of the prior quarter
      useDate.setUTCMonth(useMonth);
      useDate.setUTCDate(0);
    }

    // Return as a date object at midnight
    return new Date(
      Date.UTC(useDate.getUTCFullYear(), useDate.getUTCMonth(), useDate.getUTCDate())
    );
  }

  /**
   * Gets the last complete year end date.
   * @param fromDate Base result on this if provided else today
   * @returns End of the last complete year
   */
  static lastCompleteYear(fromDate?: Date): Date {
    const useDate = fromDate ?? new Date();
    const delYear = useDate.getUTCMonth() === 11 && useDate.getUTCDate() === 31 ? 0 : -1;
    return new Date(Date.UTC(useDate.getUTCFullYear() + delYear, 11, 31));
  }

  /**
   * Calculates the start of a period including the passed date, based on days
   * since epoch, for the defined period size. Optionally includes weekends for
   * assets that trade 7 days a week, eg crypto.
   * @param forDate The date to calculate the period for
   * @param periodSize The length of the period in days
   * @param includeWeekends Whether to include weeked days in the calculation
   * @returns The period number or null if the date is a weekend
   */
  static getPeriodDate(forDate: Date, periodSize: number, includeWeekends: boolean): Date | null {
    // Determine the week number since the epoch
    const natDay = Math.floor(forDate.getTime() / this.MSEC_DAY) - this.EPOCH_MON_OFFSET_DAYS;
    if (includeWeekends) {
      // No need to exclude weekends, convert the week back to a date
      const period = Math.floor(natDay / periodSize);
      return new Date((period * periodSize + this.EPOCH_MON_OFFSET_DAYS) * this.MSEC_DAY);
    }

    // Determine the day of the week
    const dayOfWeek = natDay % 7;
    if (dayOfWeek === 5 || dayOfWeek === 6) {
      // Unable to accept weekend day
      return null;
    }

    // Calculate the period in 5 day weeks
    const week = Math.floor(natDay / 7);
    const weekDay = week * 5 + dayOfWeek;
    const period = Math.floor(weekDay / periodSize);

    // Convert the period in the 5 day week back to a date
    const startDay = period * periodSize;
    const startWeek = Math.floor(startDay / 5);
    const startDOW = startDay % 5;
    return new Date((startWeek * 7 + startDOW + this.EPOCH_MON_OFFSET_DAYS) * this.MSEC_DAY);
  }

  /**
   * Gets the start date of the week, synchronized to the first Monday of the epoch.
   * @param tradeDate The date to find the week start for
   * @returns The start date of the week
   */
  static startOfMondayWeek(tradeDate: Date): Date {
    const epochDays = Math.floor(tradeDate.getTime() / DateUtils.MSEC_DAY);
    const weekNumber = Math.floor((epochDays + 4) / 7);
    return new Date((weekNumber * 7 - 4) * DateUtils.MSEC_DAY);
  }
}
