import IStockPriceService from "./iStockPriceService";
import IssueHistory from "../../entities/issueHistory";
import SymbolSearch from "@/entities/symbolSearch";
import { convertAVToEODHD, convertEODHDToAV } from "@/utils/symbolConverter";

const EODHD_KEY = "66697adfb0b8a1.26736468";
const EODHD_URL = "https://eodhd.com/api";

/**
 * Represents the structure of the EODHD response for historical data.
 */
type EODHDHistoryEntry = {
  date: string;
  open: number;
  high: number;
  low: number;
  close: number;
  adjusted_close?: number;
  volume: number;
};

/**
 * Represents the structure of the EODHD response for symbol search.
 */

export default class EODHDStockPriceService implements IStockPriceService {
  /**
   * Build query URL for EODHD API.
   */
  private static buildQuery(endpoint: string, params: { [key: string]: string }): string {
    const query = Object.keys(params)
      .map((key) => `${key}=${encodeURIComponent(params[key])}`)
      .join("&");
    return `${EODHD_URL}/${endpoint}?${query}&api_token=${EODHD_KEY}`;
  }

  /**
   * Fetch historical stock prices.
   */
  async queryHistory(issueOrSymbol: IssueHistory | string): Promise<IssueHistory> {
    const symbol = typeof issueOrSymbol === "string" ? issueOrSymbol : issueOrSymbol.symbol;

    console.log("queryHistory - Received symbol:", symbol);

    // Ensure symbol is properly converted before fetching
    const eodhdSymbol = convertAVToEODHD(symbol).toUpperCase(); // Convert & uppercase
    console.log(`queryHistory - Converted to EODHD format: ${eodhdSymbol}`);

    const fromDate = "1900-01-01";
    const toDate = new Date().toISOString().split("T")[0];
    const query = EODHDStockPriceService.buildQuery(`eod/${eodhdSymbol}`, {
      fmt: "json",
      from: fromDate,
      to: toDate,
    });

    console.log("queryHistory - Fetching historical data with URL:", query);

    try {
      const response = await fetch(query);
      if (!response.ok) {
        throw new Error(`Failed to fetch EODHD data: ${response.statusText}`);
      }

      const data = await response.json();
      if (!Array.isArray(data) || !data.length) {
        throw new Error("EODHD response is empty or invalid");
      }

      const daily = data.map((entry: EODHDHistoryEntry) => ({
        date: new Date(entry.date),
        close: Number(entry.adjusted_close || entry.close),
        volume: Number(entry.volume || 0),
        isCrypto: eodhdSymbol.endsWith(".cc"),
      }));

      const name =
        typeof issueOrSymbol !== "string" ? issueOrSymbol.name : "Placeholder for ${symbol}";
      return new IssueHistory(symbol, toDate, daily, name);
    } catch (error) {
      console.error("Error fetching historical data:", error);
      throw error;
    }
  }
  /**
   * Fetch issue details.
   */
  async queryIssue(symbol: string, name?: string): Promise<IssueHistory> {
    console.log("queryIssue - Received symbol:", symbol);

    // Convert AV format to EODHD format
    const eodhdSymbol = convertAVToEODHD(symbol);
    console.log(`queryIssue - Converted to EODHD format: ${eodhdSymbol}`);

    try {
      const query = EODHDStockPriceService.buildQuery(`search/${eodhdSymbol}`, {
        fmt: "json",
      });

      console.log("EODHD queryIssue API Request URL:", query);

      const response = await fetch(query);
      if (!response.ok) {
        throw new Error(`Failed to fetch symbol details: ${response.statusText}`);
      }

      // Ensure data is assigned before using it
      const data: { Code: string; Exchange: string; Name?: string; Description?: string }[] =
        await response.json();

      if (!Array.isArray(data) || data.length === 0) {
        throw new Error(`No results found for symbol: ${symbol}`);
      }

      // Find all matches
      const matches = data.filter(
        (item) => item.Code.toUpperCase() === eodhdSymbol.split(".")[0].toUpperCase()
      );

      // Prefer US exchange, fallback to first available match
      const match = matches.find((item) => item.Exchange.toUpperCase() === "US") || matches[0];

      if (!match || !match.Name) {
        console.warn("queryIssue - No Match Found for Symbol:", symbol, match);
        throw new Error(`Symbol ${symbol} not found or missing required fields in EODHD data.`);
      }

      return new IssueHistory({
        symbol: convertAVToEODHD(match.Code + "." + match.Exchange.toLowerCase()),
        name: name ?? match.Name,
        description: match.Description ?? "No description available",
        closeTime: "16:00",
        timeZone: "UTC",
        isCrypto: eodhdSymbol.endsWith(".cc"),
      });
    } catch (error) {
      console.error("queryIssue - Error occurred:", error);
      throw error;
    }
  }
  /**
   * Search for stock symbols.
   */
  async searchStockSymbol(symbol: string): Promise<SymbolSearch | null> {
    console.log("searchStockSymbol - Received symbol:", symbol);

    // Convert AV format to EODHD format
    const eodhdSymbol = convertAVToEODHD(symbol);
    console.log(`searchStockSymbol - Converted to EODHD format: ${eodhdSymbol}`);

    const query = EODHDStockPriceService.buildQuery(`search/${eodhdSymbol}`, { fmt: "json" });

    try {
      const response = await fetch(query);
      if (!response.ok) {
        throw new Error(`Failed to fetch search results: ${response.statusText}`);
      }

      const data = await response.json();
      if (!Array.isArray(data) || data.length === 0) {
        return null;
      }

      return {
        searchText: symbol,
        results: data.slice(0, 5).map((item) => ({
          symbol: convertEODHDToAV(`${item.Code}.${item.Exchange.toLowerCase()}`),
          name: item.Name,
        })),
      };
    } catch (error) {
      return null;
    }
  }
}
