import { toast } from "react-toastify";
class LoginService {
  private static instance: LoginService;
  private token: string | null = null;
  private features: string[] = [];

  static FEATURE_M0 = "FEATURE_M0";

  private constructor() {}

  // Singleton instance method
  static getInstance(): LoginService {
    if (!LoginService.instance) {
      LoginService.instance = new LoginService();
    }
    return LoginService.instance;
  }

  // Login method to authenticate user and store the token
  async login(username: string, password: string): Promise<boolean> {
    try {
      const response = await fetch("/api/user/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userName: username, password: password }),
      });
      const data = await response.json(); // Read response once

      if (!response.ok) {
        console.error("API Error Response:", data);

        // Handle expired account error (403 Forbidden)
        if (response.status === 403) {
          toast.error("⚠️ " + data.message);
        } else if (response.status === 401) {
          toast.error("Invalid credentials. Please try again.");
        } else {
          toast.error(data.message || "An error occurred. Please try again.");
        }

        return false;
      }

      this.token = data.token;

      this.features = data.features || [];

      return true;
    } catch (error) {
      console.error("Login failed:", error);
      toast.error("An unexpected error occurred. Please try again.");
      return false;
    }
  }

  // Method to retrieve the token
  getToken(): string | null {
    return this.token;
  }

  hasFeature(feature: string): boolean {
    return this.features.includes(feature);
  }

  /**
   * Logout method:
   * 1. Call the logout API endpoint (prepares for future server-side session management).
   * 2. Log any API call failures for debugging purposes.
   * 3. Clear the local token and immediately redirect the user to the landing page.
   */
  logout(): void {
    // Call the logout API endpoint
    fetch("/api/user/logout", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.token}`, // Pass the token in the header
      },
    })
      .then((response) => {
        if (!response.ok) {
          console.error("Logout API failed:", response.statusText); // Log non-200 responses
        }
      })
      .catch((error) => {
        console.error("Error calling logout API:", error); // Log any network errors
      });

    // Clear the token and redirect immediately
    this.token = null;
    window.location.href = "/"; // Redirect to the landing page
  }
}

export const loginService = LoginService.getInstance();
export { LoginService };
