import ModelIndicator from "@/services/modelIndicator";
import "./currentIndicator.css";
import { ReactElement } from "react";

export default function CurrentIndicator(props: { indicator?: ModelIndicator }): ReactElement {
  const buySell = !!props.indicator ? (props.indicator.isBuy ? "buy" : "sell") : "";
  const icon = !!props.indicator
    ? props.indicator.isBuy
      ? "trending_up"
      : "trending_down"
    : "trending_flat";
  return (
    <div className={`current-indicator ${buySell}`}>
      <span className="material-icons">{icon}</span>
    </div>
  );
}
