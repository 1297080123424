import IssueHistory from "../entities/issueHistory";
import SymbolSearch from "@/entities/symbolSearch";
import { commodityParser, cryptoParser, forexParser } from "@/parsers/issueParsers";

const KEY_USED = "issueUsed";
const KEY_ISSUE_PRE = "issueHistory_";
const MAX_CACHE_SIZE = 30;

export default class IssueCacheService {
  /**
   * Gets an issue from the cache, if present. Marks the current time in the
   * LRU cache.
   * @param symbol Symbol to fetch
   * @returns Issue history fetched from cache or null if not present
   */
  static getIssue(symbol: string): IssueHistory | null {
    // Check the cache
    const normSymbol = symbol.toUpperCase();
    const lru = JSON.parse(localStorage.getItem(KEY_USED) ?? "{}");
    if (!lru[normSymbol]) {
      // Symbol not cached
      return null;
    }

    // Get the issue from the cache
    const res = JSON.parse(localStorage.getItem(KEY_ISSUE_PRE + normSymbol) ?? "null");
    if (!res) return null;

    // Mark symbol as used now
    lru[normSymbol] = Date.now();
    localStorage.setItem(KEY_USED, JSON.stringify(lru));

    // Type conversion required for dates
    if (!res.name) {
      // Convert old record format
      res.name = res.description;
      res.description = undefined;
    }
    const history = res as IssueHistory;
    history.daily?.forEach((day) => (day.date = new Date(day.date)));
    return history;
  }

  /**
   * Saves the issue to the cache. Updates the LRU, may result in old issues
   * being purged.
   * @param issue Issue to cache
   */
  static saveIssue(issue: IssueHistory): void {
    // First attempt to update the cached symbol
    const normSymbol = issue.symbol.toUpperCase();
    const issueKey = KEY_ISSUE_PRE + normSymbol;
    const serializedIssue = JSON.stringify(issue);
    do {
      try {
        // Write the issue to the cache
        localStorage.setItem(issueKey, serializedIssue);

        // Update recently used list
        const lru = JSON.parse(localStorage.getItem(KEY_USED) ?? "{}");
        lru[normSymbol] = Date.now();
        localStorage.setItem(KEY_USED, JSON.stringify(lru));
        while (this.bumpIssueFromCache(true));
        return;
      } catch (err: unknown) {
        if (err instanceof Error && err.name !== "QuotaExceededError") {
          throw err;
        }
      }
    } while (this.bumpIssueFromCache());
  }

  /**
   * Removes the oldest symbol from the cache. If the checkMax param is true,
   * this won't do anything if the cache size is not greater than the maximum.
   * @param checkMax Should test length against max
   * @returns Bumped flag
   */
  private static bumpIssueFromCache(checkMax: boolean = false): boolean {
    // Get the current cache usage
    const lru = JSON.parse(localStorage.getItem(KEY_USED) ?? "{}");
    const length = Object.keys(lru).length;
    if (!length || (checkMax && length <= MAX_CACHE_SIZE)) {
      // No need to bump
      return false;
    }

    // Find the oldest entry
    const oldestSymbol = Object.keys(lru).reduce((a, c) => (lru[a] < lru[c] ? a : c));
    try {
      // Delete the found symbol
      delete lru[oldestSymbol];
      localStorage.removeItem(KEY_ISSUE_PRE + oldestSymbol);
      localStorage.setItem(KEY_USED, JSON.stringify(lru));
      return true;
    } catch (err: unknown) {
      if (err instanceof Error) {
        console.error(
          `Failed to delete oldest symbol :${oldestSymbol} from local cache: ${err.message}`
        );
      } else {
        console.error(`Failed to delete oldest symbol: ${oldestSymbol}`, err);
      }
      return false;
    }
  }

  /**
   * Searches for symbols in the local cache based on the provided search text.
   * If no matches are found, it suggests other cached tickers.
   *
   * @param searchText - The text to search for
   * @returns A SymbolSearch object or false if no matches are found
   */
  static searchLocalCache(searchText: string): SymbolSearch | false {
    const cachedIssues = this.getAllIssues();
    if (!cachedIssues || cachedIssues.length === 0) {
      return false;
    }

    // Array to store matched symbols with scores
    const scoredResults: { symbol: string; name: string; score: number }[] = [];
    const otherStockSuggestions: { symbol: string; name: string }[] = [];

    for (const issue of cachedIssues) {
      // Calculate the score based on searchText
      const score = this.calculateMatchScore(issue.symbol, searchText);

      // If score > 0, it matches the search
      if (score > 0) {
        scoredResults.push({
          symbol: issue.symbol,
          name: issue.name,
          score: score,
        });
      } else {
        // Suggest other cached stocks/indexes if not a match
        if (
          !commodityParser.select(issue.symbol) &&
          !cryptoParser.select(issue.symbol) &&
          !forexParser.select(issue.symbol)
        ) {
          otherStockSuggestions.push({
            symbol: issue.symbol,
            name: issue.name,
          });
        }
      }
    }

    // Sort results by score
    scoredResults.sort((a, b) => b.score - a.score);

    // Append suggestions if fewer than 5 results
    const remainingSlots = 5 - scoredResults.length;
    const finalResults = [
      ...scoredResults.slice(0, 5),
      ...otherStockSuggestions.slice(0, remainingSlots),
    ];

    // Return results or false if no matches found
    return finalResults.length > 0
      ? {
          searchText,
          exactMatch: scoredResults.length > 0 && scoredResults[0].symbol === searchText,
          results: finalResults.map((result) => ({
            symbol: result.symbol,
            name: result.name,
          })),
        }
      : false;
  }

  /**
   * Calculates a match score for a given symbol and search text.
   */
  private static calculateMatchScore(symbol: string, searchText: string): number {
    if (symbol === searchText.toUpperCase()) {
      return 100;
    } else if (symbol.startsWith(searchText.toUpperCase())) {
      return 50;
    }
    return 0;
  }

  /**
   * Retrieves all issue histories from the local cache.
   *
   * @returns An array of {@link IssueHistory} objects, each representing an issue history from the cache.
   * If no issues are found in the cache, an empty array is returned.
   */
  static getAllIssues(): IssueHistory[] {
    const lru = JSON.parse(localStorage.getItem(KEY_USED) ?? "{}");
    const cachedIssues: IssueHistory[] = [];

    Object.keys(lru).forEach((symbol) => {
      const issue = this.getIssue(symbol);
      if (issue) {
        cachedIssues.push(issue);
      }
    });

    return cachedIssues;
  }
}
