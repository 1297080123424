import React from "react";
import IssueHistory from "@/entities/issueHistory";
import ModelIndicator from "@/services/modelIndicator";
import { IndicatorPeriod } from "../assetDashboard.types";

import "./capitalExposureDetails.css";
import FormatUtils from "@/services/formatUtils";
import ModelReport from "@/services/modelReport";

type CapitalExposureDetailsProps = {
  issue: IssueHistory;
  indicator: ModelIndicator;
  period: IndicatorPeriod;
  report: ModelReport;
};

/**
 * Displays the capita exposure details.
 * TODO Consider sharing the inflection report rather than regenerating
 * @param props Details properties
 * @returns Details root element
 */
export default function CapitalExposureDetails(
  props: CapitalExposureDetailsProps
): React.ReactElement {
  const { startDate, endDate } = props.report;

  const inflectionReport = props.indicator.getInflectionReport(startDate, endDate);
  const isExposed = !!inflectionReport.totalDays && !!inflectionReport.days;
  const exposure = isExposed
    ? FormatUtils.formatPercent(inflectionReport.days / inflectionReport.totalDays)
    : "N/A";
  return (
    <div className="capital-exposure-details">
      <div className="ced-contents">
        <div className="ced-contents-label">Days Open:</div>
        <div className="ced-contents-value">{inflectionReport.days}</div>
        <div className="ced-contents-label">Days Closed:</div>
        <div className="ced-contents-value">
          {inflectionReport.totalDays - inflectionReport.days}
        </div>
        <div className="ced-contents-label">Total Days:</div>
        <div className="ced-contents-value">{inflectionReport.totalDays}</div>
      </div>
      <br />
      <div className="ced-summary">Exposure: {exposure}%</div>
    </div>
  );
}
