import React from "react";

import "./descriptionPanel.css";

import { IndicatorStrategy } from "@/entities/indicatorStrategy.ts";
import { IndicatorPeriod, IndicatorPeriods } from "./assetDashboard.types";
import IssueHistory from "@/entities/issueHistory";
import { PageTabBarTab } from "./pageTabBar";

type DescriptionPanelProps = {
  tab: PageTabBarTab;
  strategy: IndicatorStrategy;
  period: IndicatorPeriod;
  issue?: IssueHistory;
  onChange: (strategy: IndicatorStrategy, period: IndicatorPeriod) => void;
};

/**
 * Provides the description panel at the bottom of the content area.
 * This contains controls to display and select active vs passive
 * strategy, and the display period, in addition to the description
 * text.
 *
 * TODO: Once we're sure we no longer need the button controls here,
 *   remove them from the code and styling.
 *
 * @returns Panel element tree
 */
export default function DescriptionPanel(props: DescriptionPanelProps): React.ReactElement | null {
  // Selecting state
  const [isSelectingPeriod, setSelectingPeriod] = React.useState(false);

  // Rendering helpers
  const strategyClass = (strategy: IndicatorStrategy) =>
    "descr-controls-button " +
    (strategy === props.strategy ? "strategy-selected" : "strategy-unselected");
  const onSelectStrategy = (strategy: IndicatorStrategy) => props.onChange(strategy, props.period);
  const selectPeriodClass = `descr-select-period${isSelectingPeriod ? " select-showing" : ""}`;
  const noIssueClass = !props.issue ? " no-issue" : "";
  const textDescription = React.useMemo<string>(() => {
    return generateDescriptionText(props);
  }, [props]);

  // Returns the description panel elements
  return (
    <div className={"asset-dashboard-descr" + noIssueClass}>
      <div className="descr-controls" style={{ display: "none" }}>
        {" "}
        {/* TODO */}
        <div
          className={strategyClass(IndicatorStrategy.ThreeDay)}
          onClick={() => onSelectStrategy(IndicatorStrategy.ThreeDay)}
        >
          Active
          <div className="strategy-mark">
            <div />
          </div>
        </div>
        <div
          className={strategyClass(IndicatorStrategy.Weekly)}
          onClick={() => onSelectStrategy(IndicatorStrategy.Weekly)}
        >
          Passive
          <div className="strategy-mark">
            <div />
          </div>
        </div>
        <div
          className="descr-controls-button"
          onClick={() => setSelectingPeriod(!isSelectingPeriod)}
        >
          {props.period.description}
        </div>
      </div>
      <div className="descr-description">
        {textDescription}
        <div className={selectPeriodClass}>
          <div />
          <div>
            <div>
              {IndicatorPeriods.filter((period) => period !== props.period)
                .reverse()
                .map((period) => (
                  <div
                    key={period.months}
                    className="descr-period-button"
                    onClick={() => {
                      setSelectingPeriod(false);
                      props.onChange(props.strategy, period);
                    }}
                  >
                    {period.description}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

/** Produces a description based on the current display properties. */
function generateDescriptionText(props: DescriptionPanelProps): string {
  switch (!!props.issue && props.tab.tabId) {
    case "capgro":
      return "The capital growth chart displays the overall performance of both the market and the drivers model over the desired time period based upon a starting amount of 100k.";
    case "capexp":
      return "The capital exposure pie chart illustrates the proportion of time the investment has been active (open) versus inactive (closed) over the determined  period.";
    case "marprf":
      return `
     The bar chart on market performance clearly illustrates the overall returns and distinctions between the market's performance and the drivers model. To gain deeper insights into how these total returns were achieved, the performance data can be further analyzed over a shorter time horizon.
      `;
    case "rskprf":
      return `
      The Risk performance compares the maximum drawdown of the market vs the maximum loss of the drivers model over the same time period. 
The light red line depicts the maximum drawdown of the asset over a defined time period. 
The dark red line shows the drivers models maximum drawdown over that same time horizon.
      `;
    case "marsen":
      return `
   The drivers heat map allows the user to visualises market conditions and gage sentiment between buyer and sellers. 
Market conditions can vary from extreme strength (strong buy) to extreme weakness (strong sell)
      `;
  }

  // No specific description
  return "";
}
