import IssueList from "@/entities/issueList";
import { forexIssues } from "./forexParser";
import IssueSymbolParser from "./issueSymbolParser";

/**
 * Database of crypto issues.
 */
const cryptoIssues: IssueList = {
  "1ST": { name: "FirstBlood" },
  "2GIVE": { name: "GiveCoin" },
  "808": { name: "808Coin" },
  AAVE: { name: "Aave" },
  ABT: { name: "ArcBlock" },
  ABY: { name: "ArtByte" },
  AC: { name: "AsiaCoin" },
  ACT: { name: "Achain" },
  ADA: { name: "Cardano" },
  ADT: { name: "adToken" },
  ADX: { name: "AdEx" },
  AE: { name: "Aeternity" },
  AEON: { name: "Aeon" },
  AGI: { name: "SingularityNET" },
  AGRS: { name: "IDNI-Agoras" },
  AI: { name: "POLY-AI" },
  AID: { name: "AidCoin" },
  AION: { name: "Aion" },
  AIR: { name: "AirToken" },
  AKY: { name: "Akuya-Coin" },
  ALGO: { name: "Algorand" },
  ALIS: { name: "ALIS" },
  AMBER: { name: "AmberCoin" },
  AMP: { name: "Synereo" },
  AMPL: { name: "Ampleforth" },
  ANC: { name: "Anoncoin" },
  ANT: { name: "Aragon" },
  APPC: { name: "AppCoins" },
  APX: { name: "APX-Ventures" },
  ARDR: { name: "Ardor" },
  ARK: { name: "Ark" },
  ARN: { name: "Aeron" },
  AST: { name: "AirSwap" },
  ATB: { name: "ATBCoin" },
  ATM: { name: "ATMChain" },
  ATOM: { name: "Cosmos" },
  ATS: { name: "Authorship" },
  AUR: { name: "Auroracoin" },
  AVAX: { name: "Avalanche" },
  AVT: { name: "Aventus" },
  B3: { name: "B3Coin" },
  BAND: { name: "Band Protocol" },
  BAT: { name: "Basic-Attention-Token" },
  BAY: { name: "BitBay" },
  BBR: { name: "Boolberry" },
  BCAP: { name: "BCAP" },
  BCC: { name: "BitConnect" },
  BCD: { name: "Bitcoin-Diamond" },
  BCH: { name: "Bitcoin-Cash" },
  BCN: { name: "Bytecoin" },
  BCPT: { name: "BlockMason-Credit-Protocol-Token" },
  BCX: { name: "BitcoinX" },
  BCY: { name: "BitCrystals" },
  BDL: { name: "Bitdeal" },
  BEE: { name: "Bee-Token" },
  BELA: { name: "BelaCoin" },
  BET: { name: "DAO-Casino" },
  BFT: { name: "BF-Token" },
  BIS: { name: "Bismuth" },
  BITB: { name: "BitBean" },
  BITBTC: { name: "BitBTC" },
  BITCNY: { name: "BitCNY" },
  BITEUR: { name: "BitEUR" },
  BITGOLD: { name: "BitGOLD" },
  BITSILVER: { name: "BitSILVER" },
  BITUSD: { name: "BitUSD" },
  BIX: { name: "Bibox-Token" },
  BLITZ: { name: "Blitzcash" },
  BLK: { name: "Blackcoin" },
  BLN: { name: "Bolenum" },
  BLOCK: { name: "Blocknet" },
  BLZ: { name: "Bluzelle" },
  BMC: { name: "Blackmoon-Crypto" },
  BNB: { name: "Binance-Coin", commonOrder: 5 },
  BNT: { name: "Bancor-Network-Token" },
  BNTY: { name: "Bounty0x" },
  BOST: { name: "BoostCoin" },
  BOT: { name: "Bodhi" },
  BQ: { name: "bitqy" },
  BRD: { name: "Bread" },
  BRK: { name: "Breakout-Coin" },
  BRX: { name: "Breakout-Stake" },
  BSV: { name: "Bitcoin SV" },
  BTA: { name: "Bata" },
  BTC: { name: "Bitcoin", commonOrder: 1 },
  BTCB: { name: "Bitcoin BEP2" },
  BTCD: { name: "BitcoinDark" },
  BTCP: { name: "Bitcoin-Private" },
  BTG: { name: "Bitcoin-Gold" },
  BTM: { name: "Bitmark" },
  BTS: { name: "BitShares" },
  BTSR: { name: "BTSR" },
  BTT: { name: "BitTorrent" },
  BTX: { name: "Bitcore" },
  BURST: { name: "Burstcoin" },
  BUSD: { name: "Binance-USD" },
  BUZZ: { name: "BuzzCoin" },
  BYC: { name: "Bytecent" },
  BYTOM: { name: "Bytom" },
  C20: { name: "Crypto20" },
  CAKE: { name: "PancakeSwap" },
  CANN: { name: "CannabisCoin" },
  CAT: { name: "BlockCAT" },
  CCRB: { name: "CryptoCarbon" },
  CDT: { name: "Blox" },
  CFI: { name: "Cofound-it" },
  CHAT: { name: "ChatCoin" },
  CHIPS: { name: "Chips" },
  CLAM: { name: "Clams" },
  CLOAK: { name: "CloakCoin" },
  CMP: { name: "Compcoin" },
  CMT: { name: "CyberMiles" },
  CND: { name: "Cindicator" },
  CNX: { name: "Cryptonex" },
  COFI: { name: "CoinFi" },
  COMP: { name: "Compound" },
  COSS: { name: "COSS" },
  COVAL: { name: "Circuits-Of-Value" },
  CRBIT: { name: "CreditBIT" },
  CREA: { name: "CreativeCoin" },
  CREDO: { name: "Credo" },
  CRO: { name: "Crypto.com Coin" },
  CRW: { name: "Crown" },
  CSNO: { name: "BitDice" },
  CTR: { name: "Centra" },
  CTXC: { name: "Cortex" },
  CURE: { name: "CureCoin" },
  CVC: { name: "Civic" },
  DAI: { name: "Dai" },
  DAR: { name: "Darcrus" },
  DASH: { name: "Dash" },
  DATA: { name: "DATAcoin" },
  DAY: { name: "Chronologic" },
  DBC: { name: "DeepBrain-Chain" },
  DBIX: { name: "DubaiCoin" },
  DCN: { name: "Dentacoin" },
  DCR: { name: "Decred" },
  DCT: { name: "DECENT" },
  DDF: { name: "Digital-Developers-Fund" },
  DENT: { name: "Dent" },
  DFS: { name: "DFSCoin" },
  DGB: { name: "DigiByte" },
  DGC: { name: "Digitalcoin" },
  DGD: { name: "DigixDAO" },
  DICE: { name: "Etheroll" },
  DLT: { name: "Agrello-Delta" },
  DMD: { name: "Diamond" },
  DMT: { name: "DMarket" },
  DNT: { name: "district0x" },
  DOGE: { name: "DogeCoin" },
  DOPE: { name: "DopeCoin" },
  DOT: { name: "Polkadot" },
  DRGN: { name: "Dragonchain" },
  DTA: { name: "Data" },
  DTB: { name: "Databits" },
  DYN: { name: "Dynamic" },
  EAC: { name: "EarthCoin" },
  EBST: { name: "eBoost" },
  EBTC: { name: "eBTC" },
  ECC: { name: "ECC" },
  ECN: { name: "E-coin" },
  EDG: { name: "Edgeless" },
  EDO: { name: "Eidoo" },
  EFL: { name: "Electronic-Gulden" },
  EGC: { name: "EverGreenCoin" },
  EGLD: { name: "Elrond" },
  EKT: { name: "EDUCare" },
  ELA: { name: "Elastos" },
  ELEC: { name: "Electrify.Asia" },
  ELF: { name: "aelf" },
  ELIX: { name: "Elixir" },
  EMB: { name: "Embercoin" },
  EMC: { name: "Emercoin" },
  EMC2: { name: "Einsteinium" },
  ENG: { name: "Enigma" },
  ENJ: { name: "Enjin-Coin" },
  ENRG: { name: "EnergyCoin" },
  EOS: { name: "EOS" },
  EOT: { name: "EOT-Token" },
  EQT: { name: "EquiTrader" },
  ERC: { name: "EuropeCoin" },
  ETC: { name: "Ethereum-Classic" },
  ETH: { name: "Ethereum", commonOrder: 2 },
  ETHD: { name: "Ethereum-Dark" },
  ETHOS: { name: "Ethos" },
  ETN: { name: "Electroneum" },
  ETP: { name: "Metaverse-Entropy" },
  ETT: { name: "EncryptoTel" },
  EVE: { name: "Devery" },
  EVX: { name: "Everex" },
  EXCL: { name: "ExclusiveCoin" },
  EXP: { name: "Expanse" },
  FCT: { name: "Factom" },
  FIL: { name: "Filecoin" },
  FLDC: { name: "FoldingCoin" },
  FLO: { name: "FlorinCoin" },
  FLT: { name: "FlutterCoin" },
  FRST: { name: "FirstCoin" },
  FTC: { name: "Feathercoin" },
  FTT: { name: "FTX Token" },
  FUEL: { name: "Etherparty" },
  FUN: { name: "FunFair" },
  GAM: { name: "Gambit" },
  GAME: { name: "GameCredits" },
  GAS: { name: "Gas" },
  GBG: { name: "Golos Gold" },
  GBX: { name: "GoByte" },
  GBYTE: { name: "Byteball" },
  GCR: { name: "GCRCoin" },
  GEO: { name: "GeoCoin" },
  GLD: { name: "GoldCoin" },
  GNO: { name: "Gnosis-Token" },
  GNT: { name: "Golem-Tokens" },
  GOLOS: { name: "Golos" },
  GRC: { name: "Gridcoin" },
  GRT: { name: "Graph" },
  GRS: { name: "Groestlcoin" },
  GRWI: { name: "Growers-International" },
  GTC: { name: "Game" },
  GTO: { name: "Gifto" },
  GUP: { name: "Guppy" },
  GVT: { name: "Genesis-Vision" },
  GXS: { name: "GXShares" },
  HBAR: { name: "Hedera" },
  HBN: { name: "HoboNickels" },
  HEAT: { name: "HEAT" },
  HMQ: { name: "Humaniq" },
  HPB: { name: "High-Performance-Blockchain" },
  HSR: { name: "Hshare" },
  HT: { name: "Huobi Token" },
  HUSH: { name: "Hush" },
  HVN: { name: "Hive" },
  HXX: { name: "HexxCoin" },
  ICN: { name: "ICONOMI" },
  ICX: { name: "ICON" },
  IFC: { name: "Infinitecoin" },
  IFT: { name: "investFeed" },
  IGNIS: { name: "Ignis" },
  INCNT: { name: "Incent" },
  IND: { name: "Indorse-Token" },
  INF: { name: "InfChain" },
  INK: { name: "Ink" },
  INS: { name: "INS-Ecosystem" },
  INSTAR: { name: "Insights-Network" },
  INT: { name: "Internet-Node-Token" },
  INXT: { name: "Internxt" },
  IOC: { name: "IOCoin" },
  ION: { name: "ION" },
  IOP: { name: "Internet-of-People" },
  IOST: { name: "IOStoken" },
  IOTA: { name: "IOTA" },
  IOTX: { name: "IoTeX" },
  IQT: { name: "Iquant-Chain" },
  ITC: { name: "IoT-Chain" },
  IXC: { name: "iXcoin" },
  IXT: { name: "InsureX" },
  J8T: { name: "JET8" },
  JNT: { name: "Jibrel-Network" },
  KCS: { name: "KuCoin" },
  KICK: { name: "KickCoin" },
  KIN: { name: "KIN" },
  KLAY: { name: "Klaytn" },
  KMD: { name: "Komodo" },
  KNC: { name: "Kyber-Network" },
  KORE: { name: "KoreCoin" },
  KSM: { name: "Kusama" },
  LBC: { name: "LBRY-Credits" },
  LCC: { name: "Litecoin-Cash" },
  LEND: { name: "EthLend" },
  LEO: { name: "UNUS SED LEO" },
  LEV: { name: "Leverj" },
  LGD: { name: "Legends-Room" },
  LINDA: { name: "Linda" },
  LINK: { name: "ChainLink" },
  LKK: { name: "Lykke" },
  LMC: { name: "LoMoCoin" },
  LOCI: { name: "LOCIcoin" },
  LOOM: { name: "Loom-Token" },
  LRC: { name: "Loopring" },
  LSK: { name: "Lisk" },
  LTC: { name: "Litecoin" },
  LUN: { name: "Lunyr" },
  LUNA: { name: "Terra" },
  MAID: { name: "MaidSafeCoin" },
  MANA: { name: "Decentraland" },
  MATIC: { name: "Polygon" },
  MAX: { name: "Maxcoin" },
  MBRS: { name: "Embers" },
  MCAP: { name: "MCAP" },
  MCO: { name: "Monaco" },
  MDA: { name: "Moeda-Loyalty-Points" },
  MEC: { name: "Megacoin" },
  MED: { name: "MediBlock" },
  MEME: { name: "Memetic" },
  MER: { name: "Mercury" },
  MGC: { name: "MergeCoin" },
  MGO: { name: "MobileGo" },
  MINEX: { name: "Minex" },
  MINT: { name: "Mintcoin" },
  MIOTA: { name: "IOTA" },
  MITH: { name: "Mithril" },
  MKR: { name: "Maker" },
  MLN: { name: "Melon" },
  MNE: { name: "Minereum" },
  MNX: { name: "MinexCoin" },
  MOD: { name: "Modum" },
  MONA: { name: "MonaCoin" },
  MRT: { name: "Miners-Reward-Token" },
  MSP: { name: "Mothership" },
  MTH: { name: "Monetha" },
  MTN: { name: "MedToken" },
  MUE: { name: "MonetaryUnit" },
  MUSIC: { name: "Musicoin" },
  MYB: { name: "MyBit-Token" },
  MYST: { name: "Mysterium" },
  MZC: { name: "Mazacoin" },
  NAMO: { name: "Namocoin" },
  NANO: { name: "Nano" },
  NAS: { name: "Nebulas-Token" },
  NAV: { name: "Nav-Coin" },
  NBT: { name: "NuBits" },
  NCASH: { name: "Nucleus-Vision" },
  NDC: { name: "NeverDie-Coin" },
  NEBL: { name: "Neblio" },
  NEO: { name: "NEO" },
  NEOS: { name: "NeosCoin" },
  NET: { name: "Nimiq" },
  NLC2: { name: "NoLimitCoin" },
  NLG: { name: "Gulden" },
  NMC: { name: "Namecoin" },
  NMR: { name: "Numeraire" },
  NOBL: { name: "NobleCoin" },
  NOTE: { name: "DNotes" },
  NPXS: { name: "Pundi-X-Token" },
  NSR: { name: "NuShares" },
  NTO: { name: "Fujinto" },
  NULS: { name: "Nuls" },
  NVC: { name: "Novacoin" },
  NXC: { name: "Nexium" },
  NXS: { name: "Nexus" },
  NXT: { name: "Nxt" },
  OAX: { name: "openANX" },
  OBITS: { name: "Obits" },
  OCL: { name: "Oceanlab" },
  OCN: { name: "Odyssey" },
  ODEM: { name: "ODEM" },
  ODN: { name: "Obsidian" },
  OF: { name: "OFCOIN" },
  OK: { name: "OKCash" },
  OMG: { name: "OmiseGo" },
  OMNI: { name: "Omni" },
  ONION: { name: "DeepOnion" },
  ONT: { name: "Ontology" },
  OPT: { name: "Opus" },
  ORN: { name: "Orion-Protocol" },
  OST: { name: "Simple-Token" },
  PART: { name: "Particl" },
  PASC: { name: "PascalCoin" },
  PAY: { name: "TenX" },
  PBL: { name: "Pebbles" },
  PBT: { name: "Primalbase-Token" },
  PFR: { name: "Payfair" },
  PING: { name: "CryptoPing" },
  PINK: { name: "Pinkcoin" },
  PIVX: { name: "PIVX" },
  PIX: { name: "Lampix" },
  PLBT: { name: "Polybius" },
  PLR: { name: "Pillar" },
  PLU: { name: "Pluton" },
  POA: { name: "POA-Network" },
  POE: { name: "Poet" },
  POLY: { name: "Polymath" },
  POSW: { name: "PoSW-Coin" },
  POT: { name: "PotCoin" },
  POWR: { name: "Power-Ledger" },
  PPC: { name: "Peercoin" },
  PPT: { name: "Populous" },
  PPY: { name: "Peerplays" },
  PRG: { name: "Paragon-Coin" },
  PRL: { name: "Oyster-Pearl" },
  PRO: { name: "Propy" },
  PST: { name: "Primas" },
  PTC: { name: "Pesetacoin" },
  PTOY: { name: "Patientory" },
  PURA: { name: "Pura" },
  QASH: { name: "QASH" },
  QAU: { name: "Quantum" },
  QLC: { name: "Qlink" },
  QRK: { name: "Quark" },
  QRL: { name: "Quantum-Resistant-Ledger" },
  QSP: { name: "Quantstamp" },
  QTL: { name: "Quatloo" },
  QTUM: { name: "Qtum" },
  QUICK: { name: "Quickswap" },
  QWARK: { name: "Qwark" },
  R: { name: "Revain" },
  RADS: { name: "Radium" },
  RAIN: { name: "Condensate" },
  RBIES: { name: "Rubies" },
  RBX: { name: "Ripto-Bux" },
  RBY: { name: "RubyCoin" },
  RCN: { name: "Ripio-Credit-Network" },
  RDD: { name: "ReddCoin" },
  RDN: { name: "Raiden-Network-Token" },
  REC: { name: "Regalcoin" },
  RED: { name: "Redcoin" },
  REP: { name: "Augur" },
  REQ: { name: "Request-Network" },
  RHOC: { name: "RChain" },
  RIC: { name: "Riecoin" },
  RISE: { name: "Rise" },
  RLC: { name: "RLC-Token" },
  RLT: { name: "RouletteToken" },
  RPX: { name: "Red-Pulse" },
  RRT: { name: "Recovery-Right-Tokens" },
  RUFF: { name: "Ruff" },
  RUNE: { name: "THORChain" },
  RUP: { name: "Rupee" },
  RVT: { name: "Rivetz" },
  SAFEX: { name: "SafeExchangeCoin" },
  SALT: { name: "Salt" },
  SAN: { name: "Santiment-Network-Token" },
  SBD: { name: "Steem-Dollars" },
  SBTC: { name: "Super-Bitcoin" },
  SC: { name: "Siacoin" },
  SEELE: { name: "Seele" },
  SEQ: { name: "Sequence" },
  SHIB: { name: "SHIBA-INU" },
  SHIFT: { name: "SHIFT" },
  SIB: { name: "SIBCoin" },
  SIGMA: { name: "SIGMAcoin" },
  SIGT: { name: "Signatum" },
  SJCX: { name: "Storjcoin-X" },
  SKIN: { name: "SkinCoin" },
  SKY: { name: "Skycoin" },
  SLR: { name: "SolarCoin" },
  SLS: { name: "SaluS" },
  SMART: { name: "SmartCash" },
  SMT: { name: "SmartMesh" },
  SNC: { name: "SunContract" },
  SNGLS: { name: "SingularDTV" },
  SNM: { name: "SONM" },
  SNRG: { name: "Synergy" },
  SNT: { name: "Status-Network-Token" },
  SOC: { name: "All-Sports" },
  SOL: { name: "Solana", commonOrder: 6 },
  SOUL: { name: "Phantasma" },
  SPANK: { name: "SpankChain" },
  SPC: { name: "SpaceChain" },
  SPHR: { name: "Sphere" },
  SPR: { name: "SpreadCoin" },
  SNX: { name: "Synthetix-Network-Token" },
  SRN: { name: "Sirin-Labs-Token" },
  START: { name: "Startcoin" },
  STEEM: { name: "Steem" },
  STK: { name: "STK-Token" },
  STORJ: { name: "Storj" },
  STORM: { name: "Storm" },
  STQ: { name: "Storiqa" },
  STRAT: { name: "Stratis" },
  STX: { name: "Stox" },
  SUB: { name: "Substratum" },
  SWFTC: { name: "SwftCoin" },
  SWIFT: { name: "Bitswift" },
  SWT: { name: "Swarm-City" },
  SYNX: { name: "Syndicate" },
  SYS: { name: "SysCoin" },
  TAAS: { name: "Taas" },
  TAU: { name: "Lamden" },
  TCC: { name: "The-ChampCoin" },
  TFL: { name: "True-Flip" },
  THC: { name: "HempCoin" },
  THETA: { name: "Theta-Token" },
  TIME: { name: "Time" },
  TIX: { name: "Blocktix" },
  TKN: { name: "TokenCard" },
  TKR: { name: "Trackr" },
  TKS: { name: "Tokes" },
  TNB: { name: "Time-New-Bank" },
  TNT: { name: "Tierion" },
  TOA: { name: "ToaCoin" },
  TRAC: { name: "OriginTrail" },
  TRC: { name: "Terracoin" },
  TRCT: { name: "Tracto" },
  TRIBE: { name: "Tribe" },
  TRIG: { name: "Triggers" },
  TRST: { name: "Trustcoin" },
  TRUE: { name: "TrueChain" },
  TRUST: { name: "TrustPlus" },
  TRX: { name: "Tronix" },
  TUSD: { name: "TrueUSD" },
  TX: { name: "TransferCoin" },
  UBQ: { name: "Ubiq" },
  UKG: { name: "UnikoinGold" },
  ULA: { name: "Ulatech" },
  UNB: { name: "UnbreakableCoin" },
  UNI: { name: "Uniswap" },
  UNITY: { name: "SuperNET" },
  UNO: { name: "Unobtanium" },
  UNY: { name: "Unity-Ingot" },
  UP: { name: "UpToken" },
  URO: { name: "Uro" },
  USDT: { name: "Tether", commonOrder: 3 },
  UST: { name: "TerraUSD" },
  UTK: { name: "UTrust" },
  VEE: { name: "BLOCKv" },
  VEN: { name: "VeChain" },
  VERI: { name: "Veritaseum" },
  VET: { name: "VeChain" },
  VIA: { name: "Viacoin" },
  VIB: { name: "Viberate" },
  VIBE: { name: "Vibe" },
  VIVO: { name: "VIVO" },
  VOISE: { name: "Voise" },
  VOX: { name: "Voxels" },
  VPN: { name: "VPNCoin" },
  VRC: { name: "Vericoin" },
  VRM: { name: "Verium" },
  VRS: { name: "Veros" },
  VSL: { name: "vSlice" },
  VTC: { name: "Vertcoin" },
  VTR: { name: "vTorrent" },
  WABI: { name: "WaBi" },
  WAN: { name: "Wanchain" },
  WAVES: { name: "Waves" },
  WAX: { name: "Wax-Token" },
  WBTC: { name: "Wrapped Bitcoin" },
  WCT: { name: "Waves-Community" },
  WDC: { name: "WorldCoin" },
  WGO: { name: "WavesGo" },
  WGR: { name: "Wagerr" },
  WINGS: { name: "Wings" },
  WPR: { name: "WePower" },
  WTC: { name: "Walton" },
  WTT: { name: "Giga-Watt-Token" },
  XAS: { name: "Asch" },
  XAUR: { name: "Xaurum" },
  XBC: { name: "Bitcoin-Plus" },
  XBY: { name: "XtraBYtes" },
  XCN: { name: "Cryptonite" },
  XCP: { name: "Counterparty" },
  XDN: { name: "DigitalNote" },
  XEL: { name: "Elastic" },
  XEM: { name: "NEM" },
  NEM: { name: "NEM" },
  XHV: { name: "Haven-Protocol" },
  XID: { name: "Sphere-Identity" },
  XLM: { name: "Stellar" },
  XMG: { name: "Magi" },
  XMR: { name: "Monero" },
  XMT: { name: "Metal" },
  XMY: { name: "Myriadcoin" },
  XPM: { name: "Primecoin" },
  XRL: { name: "Rialto" },
  XRP: { name: "Ripple", commonOrder: 4 },
  XSPEC: { name: "Spectrecoin" },
  XST: { name: "Stealthcoin" },
  XTZ: { name: "Tezos" },
  XUC: { name: "Exchange-Union" },
  XVC: { name: "Vcash" },
  XVG: { name: "Verge" },
  XWC: { name: "WhiteCoin" },
  XZC: { name: "ZCoin" },
  XZR: { name: "ZrCoin" },
  YEE: { name: "Yee" },
  YOYOW: { name: "YOYOW" },
  ZCC: { name: "ZcCoin" },
  ZCL: { name: "Zclassic" },
  ZCO: { name: "Zebi" },
  ZEC: { name: "Zcash" },
  ZEN: { name: "ZenCash" },
  ZET: { name: "Zetacoin" },
  ZIL: { name: "Zilliqa" },
  ZLA: { name: "Zilla" },
  ZRX: { name: "0x" },
};

/** Parses symbols for crypto issues. */
const cryptoParser = new IssueSymbolParser("Crypto", "CO", cryptoIssues, forexIssues);

export { cryptoIssues, cryptoParser };
