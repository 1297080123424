export default class FormatUtils {
  /** Formats a fraction as a friendly display percentage. */
  static formatPercent(percentage?: number): string {
    return Number.isFinite(percentage) ? (percentage! * 100).toFixed(1) : "N/A";
  }

  /** Formats large numbers with display friendly notation. */
  static formatLargeNumber(num: number | undefined): string {
    if (num === undefined) {
      return "N/A";
    }
    if (num >= 1e9) {
      return (num / 1e9).toFixed(2) + "B";
    }
    if (num >= 1e6) {
      return (num / 1e6).toFixed(2) + "M";
    }
    if (num >= 1e3) {
      return (num / 1e3).toFixed(2) + "k";
    }
    return num.toString();
  }

  /** Formats a number to a specified significant figures  */
  static toSignificantFigures(num: number, sigFigs: number): string {
    if (num === 0) return "0";
    const d = Math.ceil(Math.log10(Math.abs(num)));
    const power = sigFigs - d;
    const magnitude = Math.pow(10, power);
    const shifted = Math.round(num * magnitude);
    return (shifted / magnitude).toString();
  }
}
