import React from "react";
import ModelIndicator from "@/services/modelIndicator";
import ChromeService from "@/services/chromeService";
import { Doughnut } from "react-chartjs-2";
import { IndicatorPeriod } from "../assetDashboard.types";
import ModelReport from "@/services/modelReport";

/** Specifies the capital exposure chart's properties. */
type CapitalExposureChartProps = {
  period: IndicatorPeriod;
  indicator: ModelIndicator;
  report: ModelReport;
};

/**
 * Displays the capital exposure chart.
 * @param props Chart properties
 * @returns Chart root element
 */
export default function CapitalExposureChart(props: CapitalExposureChartProps): React.ReactElement {
  const { startDate, endDate } = props.report!;
  const report = props.indicator.getInflectionReport(startDate, endDate);

  // Determine the chart properties
  const chartData = {
    labels: ["Open Position", "Closed Position"],
    datasets: [
      {
        label: "Exposure",
        data: [report.days, Math.max(report.totalDays - report.days, 0)],
        backgroundColor: [ChromeService.graphColor.positive, ChromeService.graphColor.neutral],
      },
    ],
  };
  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div
      style={{
        position: "absolute",
        inset: "4pt",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Doughnut data={chartData} options={chartOptions} />
    </div>
  );
}
