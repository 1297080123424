import IssueHistory from "@/entities/issueHistory";
import IssueCacheService from "./issueCacheService";
import DashboardService from "./dashboardService";

/**
 * Loads issue histories from cache or server API. Also searches for issues.
 */
export default class IssueHistoryLoader {
  /**
   * Loads the issue details and history directly from the service.
   * Skipping cache logic since it's out of scope for now.
   *
   * @param symbol - The symbol to fetch details for (e.g., AAPL)
   * @param name - Optional override for the name
   * @returns - Loaded issue history or error message
   */
  static async loadIssue(symbol: string, _name?: string): Promise<IssueHistory | string> {
    let issue: IssueHistory | null = IssueCacheService.getIssue(symbol);
    if (!navigator.onLine) {
      return issue ?? "Offline, Unable to Load Issue History";
    }

    let isOutOfDate = true;
    if (
      issue?.daily &&
      issue.daily.length &&
      issue.refreshDate &&
      issue.closeTime &&
      issue.timeZone
    ) {
      const DAY_MS = 24 * 60 * 60 * 1000;
      const useZone = issue.timeZone.match(/([+-]\d{2})$/)?.[0] ?? "";
      const fmtZone = !useZone.length || useZone.includes(":") ? "" : ":00";
      const lastClose = `${issue.refreshDate}T${issue.closeTime}:00.000${useZone}${fmtZone}`;
      const dateClose = new Date(lastClose);
      const nextClose = dateClose.getTime() + DAY_MS;
      if (Date.now() < nextClose) {
        isOutOfDate = false;
      }
    }

    try {
      if (!issue || isOutOfDate) {
        const response = await DashboardService.getSymbol(symbol);
        if (typeof response === "string") {
          return response;
        }
        issue = response;

        IssueCacheService.saveIssue(issue);
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        console.error(`Error fetching issue for symbol "${symbol}":`, err.message);
      } else {
        console.error(`Error fetching issue for symbol "${symbol}":`, err);
      }
      return issue && (issue.daily?.length ?? 0)
        ? issue
        : `Unable To Lookup Symbol "${symbol.toUpperCase()}"`;
    }

    return issue;
  }
}
