import ModelIndicator from "../services/modelIndicator";

/** Collects the data for an issue on a single day. */
export module Inflection {
  export enum Type {
    Buy = "Buy",
    Sell = "Sell",
    Neutral = "Neutral",
  }
  export class Point {
    readonly date: Date;
    readonly close: number;
    readonly type: Type;

    constructor(date: Date, close: number, type: Type) {
      this.date = date;
      this.close = close;
      this.type = type;
    }

    static buySell(date: Date, close: number, ind: number): Point | null {
      if (close === ind) {
        return null;
      }
      const type = close > ind ? Type.Buy : Type.Sell;
      return new Point(date, close, type);
    }
  }

  /** Traverses the inflection points forward -- optimization to track current state in ordered dates. */
  export class ForwardCursor {
    private curDate: Date;
    private inflections: Inflection.Point[];
    private index: number;

    constructor(startDate: Date, indicator: ModelIndicator) {
      const inflections = indicator.getInflections();
      if (inflections.length === 0) {
        // Push a point representing the state for the whole indicator
        inflections.push(
          new Point(
            indicator.closeDate[0],
            indicator.closeValue[0],
            indicator.isBuy ? Inflection.Type.Buy : Inflection.Type.Sell
          )
        );
      }
      this.curDate = startDate;
      this.inflections = inflections;
      this.index = 0;
      this.updateIndex();
    }

    get date(): Date {
      return this.curDate;
    }

    next(): Inflection.Point {
      this.curDate = new Date(this.curDate.setUTCDate(this.curDate.getUTCDate() + 1));
      this.updateIndex();
      return this.inflections[this.index];
    }

    private updateIndex(): void {
      while (
        this.index < this.inflections.length - 1 &&
        this.curDate >= this.inflections[this.index + 1].date
      ) {
        ++this.index;
      }
    }
  }
}
