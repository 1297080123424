import React from "react";
import ModelIndicator from "@/services/modelIndicator";
import ModelReport from "@/services/modelReport";
import ChromeService from "@/services/chromeService";
import { Line } from "react-chartjs-2";
import { ChartOption, IndicatorPeriod } from "../assetDashboard.types";
import { IndicatorStrategy } from "@/entities/indicatorStrategy.ts";
import { Scale, CoreScaleOptions, Tick } from "chart.js";

type CapitalGrowthChartProps = {
  period: IndicatorPeriod;
  strategy: IndicatorStrategy;
  indicator: ModelIndicator;
  chartOptions?: ChartOption[];
  setChartOption?: (options: ChartOption[]) => void;
  report: ModelReport;
};

export default function CapitalGrowthChart(props: CapitalGrowthChartProps): React.ReactElement {
  const { capitalGrowthMarket, capitalGrowthModel, startDate, endDate } = props.report!;

  // Calculate normalized indicator display
  const { startIdx, endIdx } = props.indicator.indexRange(startDate, endDate);
  const indBuy = props.indicator.buyScaled(startIdx, endIdx);
  const labels = indBuy.map(() => "");

  // Determine display options
  const showModel = !!props.chartOptions?.find(
    (opt) => opt.isEnabled && ["cpmodel", "cpboth"].includes(opt.id)
  );
  const showGrowth = !!props.chartOptions?.find(
    (opt) => opt.isEnabled && ["cpprice", "cpboth"].includes(opt.id)
  );

  // Prepare overlapping segments
  const overlappingLine = capitalGrowthMarket.map((value, index) =>
    value === capitalGrowthModel[index] ? value : null
  );

  // Find global max/min to sync y-axis
  const growthChartData = {
    labels: Array(capitalGrowthMarket.length).fill(""),
    datasets: [
      {
        label: "Model = Market",
        data: overlappingLine,
        borderColor: ChromeService.graphColor.neutral,
        borderWidth: 2,
        pointRadius: 0,
        spanGaps: true,
      },
      {
        label: "Market Growth",
        data: capitalGrowthMarket,
        borderColor: ChromeService.graphColor.marketPositive,
        borderWidth: 2,
        pointRadius: 0,
      },
      {
        label: "Model Growth",
        data: capitalGrowthModel,
        borderColor: ChromeService.graphColor.positive,
        borderWidth: 2,
        pointRadius: 0,
      },
    ],
  };

  const growthChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: { display: false },
      y: {
        grid: { display: false },
        ticks: {
          //formatter for the ticks on y axis
          callback: function (tickValue: string | number, _index: number, _values: Tick[]) {
            if (typeof tickValue === "number") {
              if (tickValue >= 1000000) return "$" + (tickValue / 1000000).toFixed(1) + "M"; //conversion 'M' for millions
              if (tickValue >= 1000) return "$" + (tickValue / 1000).toFixed(1) + "k"; // coversion for 'k; for housands
              return "$" + tickValue.toFixed(1);
            }
            return tickValue.toString(); // Fallback for strings
          },
        },
      },
    },
    plugins: {
      legend: { display: true },
    },
  };

  const modelChartData = showModel && {
    labels,
    datasets: [
      {
        label: "Buy Indicator",
        data: indBuy,
        segment: {
          borderColor: (ctx: { p1DataIndex: number }) => {
            const idx = ctx.p1DataIndex;
            const ind = indBuy[idx];
            return ind > 0 ? ChromeService.graphColor.positive : ChromeService.graphColor.negative;
          },
        },
      },
    ],
  };

  const modelChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    pointRadius: 0,
    borderWidth: 1,
    scales: {
      x: { display: false },
      y: {
        grid: {
          display: false,
          color: "transparent",
        },
        ticks: {
          color: "transparent", // Take up space but don't show
          callback: function (
            this: Scale<CoreScaleOptions>, // Correctly typed 'this' for the scale
            tickValue: string | number, // Matches expected tick value type
            _index: number, // Index of the tick
            _ticks: Tick[] // Array of ticks (even if not used)
          ) {
            if (typeof tickValue === "number") {
              return `///${tickValue.toFixed(1)}`; // Custom tick formatting for numbers
            }
            return ""; // Fallback for non-numeric values
          },
        },
      },
    },
    plugins: {
      legend: { display: false },
    },
  };

  // Render the chart based on selected options
  return (
    <div style={{ position: "absolute", inset: 0, padding: "4pt" }}>
      {showGrowth && (
        <div style={{ height: showModel ? "66%" : "100%" }}>
          <Line data={growthChartData} options={growthChartOptions} />
        </div>
      )}
      {showModel && (
        <div style={{ height: showGrowth ? "34%" : "100%" }}>
          <Line
            data={modelChartData || { labels: [], datasets: [] }} // Fallback for undefined data
            options={modelChartOptions}
          />
        </div>
      )}
    </div>
  );
}

const growthOption: ChartOption = {
  chart: "capgro",
  id: "cpprice",
  name: "Growth",
  isEnabled: true,
  radioGroup: "cpdisplay",
};
const modelOption: ChartOption = {
  chart: "capgro",
  id: "cpmodel",
  name: "Model",
  isEnabled: false,
  radioGroup: "cpdisplay",
};
const bothOption: ChartOption = {
  chart: "capgro",
  id: "cpboth",
  name: "Strength",
  isEnabled: false,
  radioGroup: "cpdisplay",
};
export const printOptions: ChartOption[] = [growthOption];

export function getDefaultCapitalGrowthOptions(): ChartOption[] {
  return [growthOption, modelOption, bothOption];
}
