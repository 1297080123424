import ModelIndicator from "./modelIndicator";
import { ema } from "indicatorts";
import IssueDaily from "../entities/issueDaily";
import { IndicatorStrategy } from "../entities/indicatorStrategy";
import { Inflection } from "../entities/inflectionPoint";
import DateUtils from "./dateUtils";
import IssueHistory from "@/entities/issueHistory";

/**
 * A builder class for constructing a ModelIndicator based on issue daily data.
 * The ModelIndicatorBuilder class calculates the indicator values and aggregates for different periods.
 *
 * @see ModelIndicator
 */
export class ModelIndicatorBuilder {
  private daily: IssueDaily[];
  private period: IndicatorStrategy;
  static readonly avgPeriod = 21;
  private isCrypto: boolean;

  constructor(issueHistory: IssueHistory, period: IndicatorStrategy) {
    this.daily = issueHistory.daily ?? [];
    this.period = period;
    this.isCrypto = issueHistory.isCrypto;
  }

  build(): ModelIndicator {
    const instance = new ModelIndicator();

    // Determine close data for period
    instance.period = this.period;
    if (this.period === IndicatorStrategy.Daily) {
      instance.closeDate = this.daily.map((day) => day.date);
      instance.closeValue = this.daily.map((day) => day.close);
    } else {
      // Requires calculation
      instance.closeDate = [];
      instance.closeValue = [];
      if (this.period === IndicatorStrategy.ThreeDay) {
        this.calculateThreeDay(instance, this.daily, this.isCrypto);
      } else if (this.period === IndicatorStrategy.Weekly) {
        this.calculateWeekly(instance, this.daily);
      } else if (this.period === IndicatorStrategy.Monthly) {
        this.calculateMonthly(instance, this.daily);
      } else if (this.period === IndicatorStrategy.ThreeMonth) {
        this.calculateThreeMonthly(instance, this.daily);
      } else {
        throw new Error("Invalid period for Agora indicator");
      }
    }

    // Calculate the indicator
    instance.indValue = ema(ModelIndicatorBuilder.avgPeriod, instance.closeValue);
    instance.indClose = instance.indValue.map((val, idx) => instance.closeValue[idx] - val);

    // Calculate inflection points
    let lastPoint: Inflection.Point | null;
    instance.closeValue.forEach((close, idx) => {
      const date = instance.closeDate[idx];
      const ind = instance.indValue[idx];
      const point = Inflection.Point.buySell(date, close, ind);
      if (!point) {
        return;
      }
      if (!lastPoint) {
        lastPoint = point;
      } else if (lastPoint.type !== point.type) {
        instance.inflections.push(point);
        lastPoint = point;
      }
    });

    // Calculate aggregates
    instance.count = instance.closeValue.length;
    instance.closeMin = Math.min.apply(null, instance.closeValue);
    instance.closeMax = Math.max.apply(null, instance.closeValue);
    instance.indMin = Math.min.apply(null, instance.indValue);
    instance.indMax = Math.max.apply(null, instance.indValue);
    instance.min = Math.min(instance.closeMin, instance.indMin);
    instance.max = Math.max(instance.closeMax, instance.indMax);

    return instance;
  }

  /**
   * Append aggregate close data for period.
   * @param periodDate Date for the start of the period
   * @param periodValues Raw close data for period
   */
  private appendPeriod(instance: ModelIndicator, periodDate: Date, periodValues: number[]): void {
    if (periodValues.length) {
      const sum = periodValues.reduce((acc, cur) => acc + cur, 0);
      instance.closeValue.push(sum / periodValues.length);
      instance.closeDate.push(periodDate);
    }
  }

  /**
   * Calculates three-day close data based on daily.
   * Assumes this.closeData initialized to empty array prior to call.
   * Excludes weekends for everything except for crypto which includes all days.
   * @param daily Daily historic data
   * @param isCrypto Indicates issue is crypto and doesn't respect banking days
   */
  private calculateThreeDay(
    instance: ModelIndicator,
    daily: IssueDaily[],
    isCrypto: boolean
  ): void {
    let curPeriod: Date | null = null;
    let span: number[] = [];

    daily.forEach((day) => {
      const dayPeriod = DateUtils.getPeriodDate(day.date, 3, isCrypto);
      if (dayPeriod === null) {
        // Invalid trading day
        return;
      }
      if (curPeriod === null || dayPeriod.getTime() !== curPeriod.getTime()) {
        // Append last period
        if (span.length > 0 && curPeriod !== null) {
          this.appendPeriod(instance, curPeriod, span);
        }
        // Prepare next period
        curPeriod = dayPeriod;
        span = [day.close];
      } else {
        span.push(day.close);
      }
    });

    // Append the final period
    // Append the final period
    if (span.length > 0 && curPeriod !== null) {
      this.appendPeriod(instance, curPeriod, span);
    }
  }

  /**
   * Calculates weekly close data based on daily.
   * Assumes this.closeData initialized to empty array prior to call.
   * @param daily Daily historic data
   */
  private calculateWeekly(instance: ModelIndicator, daily: IssueDaily[]): void {
    let curPeriod = 0;
    let curDate = new Date();
    let span: number[] = [];

    // Iterate over each day's trading data
    daily.forEach((day) => {
      const weekStart = DateUtils.startOfMondayWeek(day.date);
      const weekNumber = weekStart.getTime() / DateUtils.MSEC_DAY;

      if (weekNumber !== curPeriod) {
        if (curPeriod !== 0) {
          this.appendPeriod(instance, curDate, span);
        }

        // Prepare for the next period
        curPeriod = weekNumber;
        curDate = weekStart;
        span = [day.close];
      } else {
        span.push(day.close);
      }
    });

    // Append the final period if there is any remaining data
    this.appendPeriod(instance, curDate, span);
  }

  /**
   * Calculates monthly close data based on daily.
   * Assumes this.closeData initialized to empty array prior to call.
   * @param daily Daily historic data
   */
  private calculateMonthly(instance: ModelIndicator, daily: IssueDaily[]): void {
    let month = -1;
    let monthValues: number[] = [];
    let date = new Date();
    daily.forEach((day) => {
      // Do we have new month?
      if (day.date.getMonth() !== month) {
        // Next month
        this.appendPeriod(instance, date, monthValues);
        month = day.date.getMonth();
        monthValues = [];
        date = new Date(`${day.date.getFullYear()}-${day.date.getMonth() + 1}Z`);
      }
      if (!isNaN(day.close)) {
        // Append month in week
        monthValues.push(day.close);
      }
    });

    // Accumulate last month
    this.appendPeriod(instance, date, monthValues);
  }

  /**
   * Calculates three-monthly close data based on daily.
   * Assumes this.closeData initialized to empty array prior to call.
   * @param daily Daily historic data
   */
  private calculateThreeMonthly(instance: ModelIndicator, daily: IssueDaily[]): void {
    let months: number[] = [];
    let monthsValues: number[] = [];
    let date = new Date();
    daily.forEach((day) => {
      // Do we have a new month?
      const dayMonth = day.date.getMonth();
      if (!months.includes(dayMonth)) {
        // Next months
        this.appendPeriod(instance, date, monthsValues);
        months = [dayMonth, (dayMonth + 1) % 12, (dayMonth + 2) % 12];
        monthsValues = [];
        date = new Date(`${day.date.getFullYear()}-${day.date.getMonth() + 1}Z`);
      }
      if (!isNaN(day.close)) {
        // Append month in week
        monthsValues.push(day.close);
      }
    });

    // Accumulate last month
    this.appendPeriod(instance, date, monthsValues);
  }
}
