// src/client/voucherRedemptionService.ts

export interface VoucherRedemptionRequest {
  voucherCode: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  company: string;
  password: string;
}

export interface VoucherRedemptionResponse {
  token: string;
  expiresAt: string;
  message: string;
}

export const voucherRedemptionService = {
  async redeemVoucher(data: VoucherRedemptionRequest): Promise<VoucherRedemptionResponse> {
    try {
      const response = await fetch("/api/public/voucher/redeem", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message || "Failed to redeem voucher");
      }
      return responseData;
    } catch (error: unknown) {
      // Optional: perform error type checking here if needed
      if (error instanceof Error) {
        throw error;
      }
      throw new Error("An unknown error occurred during voucher redemption");
    }
  },
};
