/**
 * Convert AV symbol format to EODHD format.
 * @param symbol AV format symbol (e.g., "fx.eur.usd" or "co.btc.usd")
 * @returns EODHD format symbol (e.g., "eurusd.forex" or "btc-usd.cc")
 */
export function convertAVToEODHD(symbol: string): string {
  const lowerSymbol = symbol.toLowerCase(); // Convert input to lowercase

  if (lowerSymbol.startsWith("fx.")) {
    return lowerSymbol.replace("fx.", "").replace(".", "") + ".forex";
  }

  if (lowerSymbol.startsWith("co.")) {
    return lowerSymbol.replace("co.", "").replace(/\./g, "-") + ".cc";
  }

  return lowerSymbol; // Return as-is if no conversion needed
}

/**
 * Convert EODHD symbol format to AV format.
 * @param symbol EODHD format symbol (e.g., "eurusd.forex" or "btc-usd.cc")
 * @returns AV format symbol (e.g., "fx.eur.usd" or "co.btc.usd")
 */
export function convertEODHDToAV(symbol: string): string {
  if (symbol.endsWith(".forex")) {
    // Convert Forex (eurusd.forex → fx.eur.usd)
    const baseQuote = symbol.replace(".forex", ""); // Remove .forex
    return `fx.${baseQuote.slice(0, 3)}.${baseQuote.slice(3, 6)}`.toLowerCase(); // EURUSD → fx.eur.usd
  }

  if (symbol.endsWith(".cc")) {
    // Convert Crypto (btc-usd.cc → co.btc.usd)
    const baseQuote = symbol.replace(".cc", ""); // Remove .cc
    return `co.${baseQuote.replace("-", ".")}`.toLowerCase(); // BTC-USD → co.btc.usd
  }

  return symbol; // Return as-is if no conversion needed
}
