import React, { useMemo } from "react";
import ModelIndicator from "@/services/modelIndicator";
import CurrentIndicator from "../../currentIndicator/currentIndicator";
import FormatUtils from "@/services/formatUtils";
import { IndicatorPeriod } from "../assetDashboard.types";
import IssueHistory from "@/entities/issueHistory";
import ModelReport from "@/services/modelReport";

import "./capitalGrowthDetails.css";

type CapitalGrowthDetailsProps = {
  issue: IssueHistory;
  indicator: ModelIndicator;
  period: IndicatorPeriod;
  report: ModelReport;
  showSingleInflection?: boolean;
};

/**
 * Displays the capital growth details.
 * @param props Details properties
 * @returns Details root element
 */
export default function CapitalGrowthDetails(props: CapitalGrowthDetailsProps): React.ReactElement {
  const positionDesc = props.indicator.isBuy ? "Buy" : "Sell";
  const dateFormatter = new Intl.DateTimeFormat(undefined, {
    dateStyle: "medium",
  });

  const {
    marketValue,
    modelValue,
    marketCagr,
    modelCagr,
    inflections,
    modelSharpeRatio,
    marketSharpeRatio,
    formattedMarketDriversScore,
    formattedModelDriversScore,
    overallMarketRisk,
    overallModelRisk,
    lastInflection,
  } = useMemo(() => {
    // Display the last 5 inflections
    const { report } = props;
    const inflections = props.indicator.getInflections(report.startDate).slice(-5).reverse();
    const lastInflection = inflections[0];

    // Calculate Sharpe Ratio
    const modelSharpeRatio = report.modelSharpeRatio;
    const marketSharpeRatio = report.marketSharpeRatio;

    // Calculate Drivers Score
    const marketDriversScore = report.marketDriversScore;
    const modelDriversScore = report.modelDriversScore;

    // Format the Drivers scores
    const formattedMarketDriversScore = FormatUtils.toSignificantFigures(marketDriversScore, 3);
    const formattedModelDriversScore =
      modelDriversScore !== null ? FormatUtils.toSignificantFigures(modelDriversScore, 3) : "N/A";

    // Calculate Overall Risks
    const overallMarketRisk = report.overallMarketRisk * 100;
    const overallModelRisk = report.overallModelRisk * 100;

    // Return display details
    return {
      marketValue: report.capitalGrowthMarketValue,
      modelValue: report.capitalGrowthModelValue,
      marketCagr: report.cagrMarket,
      modelCagr: report.cagrModel,
      inflections,
      modelSharpeRatio,
      marketSharpeRatio,
      formattedMarketDriversScore,
      formattedModelDriversScore,
      overallMarketRisk,
      overallModelRisk,
      lastInflection,
    };
  }, [props.report, props.indicator]);

  return (
    <div className="capital-growth-details">
      <div>
        <CurrentIndicator indicator={props.indicator} />
        &nbsp;&nbsp;{positionDesc}
      </div>
      <div className="report-heading">Current Position Overview</div>
      <div className="report-section">
        <div className="report-contents">
          <div className="report-col-heading">Date</div>
          <div className="report-col-heading">Position</div>
          {props.showSingleInflection && lastInflection ? (
            <>
              <div>{dateFormatter.format(lastInflection.date)}</div>
              <div>{lastInflection.type}</div>
            </>
          ) : (
            inflections.map((point) => (
              <React.Fragment key={point.date.toISOString()}>
                <div>{dateFormatter.format(point.date)}</div>
                <div>{point.type}</div>
              </React.Fragment>
            ))
          )}
        </div>
      </div>
      <div className="report-grid">
        <div className="report-heading">Growth</div>
        <div> Market: ${FormatUtils.formatLargeNumber(marketValue)}</div>
        <div> Model: ${FormatUtils.formatLargeNumber(modelValue)}</div>
        <div className="report-heading">CAGR</div>
        <div>Market: {marketCagr.toFixed(1)}%</div>
        <div>Model: {modelCagr.toFixed(1)}%</div>
        <div className="report-heading">Sharpe Ratio</div>
        <div>Market: {marketSharpeRatio.toFixed(2)}</div>
        <div>Model: {modelSharpeRatio.toFixed(2)}</div>
        <div className="report-heading">Drivers Score</div>
        <div>Market: {formattedMarketDriversScore}</div>
        <div>Model: {formattedModelDriversScore}</div>
        <div className="report-heading">Maximum Drawdown</div>
        <div>Market: {overallMarketRisk.toFixed(1)}%</div>
        <div>Model: {overallModelRisk.toFixed(1)}%</div>
      </div>
    </div>
  );
}
