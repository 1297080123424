import React, { useState } from "react";
import { toast } from "react-toastify";
import LookupSymbol from "@/client/components/lookupSymbol";
import IssueHistoryLoader from "@/services/issueHistoryLoader";
import IssueHistory from "@/entities/issueHistory";
import ModelIndicator from "@/services/modelIndicator";
import { IndicatorStrategy } from "@/entities/indicatorStrategy";
import { loginService } from "@/client/loginSerivce";
import "./landingPanel.css";
import { ModelIndicatorBuilder } from "@/services/modelIndicatorBuilder";
import { voucherRedemptionService } from "@/client/components/assetDashboard/voucherRedemptionForm";

interface LandingPanelProps {
  onSymbolLookup: (issue: IssueHistory, indicators: ModelIndicator[]) => void;
}

const LandingPanel: React.FC<LandingPanelProps> = ({ onSymbolLookup }) => {
  // --------------------------------
  // Login states
  // --------------------------------
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentMode, setCurrentMode] = useState<"login" | "resetRequest" | "resetValidation">(
    "login"
  );
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // --------------------------------
  // Contact form states
  // --------------------------------
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactCompany, setContactCompany] = useState("");
  const [contactNotes, setContactNotes] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Inquiry type (two options: "demo" or "general")
  const [inquiryType, setInquiryType] = useState<"demo" | "general" | null>(null);

  // --------------------------------
  // Voucher redemption states
  // --------------------------------
  const [isVoucherModalOpen, setIsVoucherModalOpen] = useState(false);
  const [voucherCode, setVoucherCode] = useState("");
  const [voucherFirstName, setVoucherFirstName] = useState("");
  const [voucherLastName, setVoucherLastName] = useState("");
  const [voucherEmail, setVoucherEmail] = useState("");
  const [voucherPhoneNumber, setVoucherPhoneNumber] = useState("");
  const [voucherCompany, setVoucherCompany] = useState("");
  const [voucherPassword, setVoucherPassword] = useState("");
  const [voucherConfirmPassword, setVoucherConfirmPassword] = useState("");
  const [isVoucherSubmitting, setIsVoucherSubmitting] = useState(false);

  // --------------------------------
  // Symbol lookup logic
  // --------------------------------
  const lookupSymbol = (symbol: string): void => {
    const toastId = toast.loading("Looking up issue...");
    IssueHistoryLoader.loadIssue(symbol).then((loadResult) => {
      if (typeof loadResult === "string") {
        toast.update(toastId, {
          render: loadResult,
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
        });
      } else {
        toast.dismiss(toastId);
        const loadedIssue: IssueHistory = loadResult;
        const indicators = [
          new ModelIndicatorBuilder(loadedIssue, IndicatorStrategy.Daily).build(),
          new ModelIndicatorBuilder(loadedIssue, IndicatorStrategy.ThreeDay).build(),
          new ModelIndicatorBuilder(loadedIssue, IndicatorStrategy.Weekly).build(),
          new ModelIndicatorBuilder(loadedIssue, IndicatorStrategy.Monthly).build(),
          new ModelIndicatorBuilder(loadedIssue, IndicatorStrategy.ThreeMonth).build(),
        ];
        onSymbolLookup(loadedIssue, indicators);
      }
    });
  };

  // --------------------------------
  // Voucher Redemption Handler
  // --------------------------------
  const handleVoucherRedemptionSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (voucherPassword !== voucherConfirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }
    setIsVoucherSubmitting(true);
    try {
      const data = {
        voucherCode,
        firstName: voucherFirstName,
        lastName: voucherLastName,
        email: voucherEmail,
        phoneNumber: voucherPhoneNumber,
        company: voucherCompany,
        password: voucherPassword,
        confirmPassword: voucherConfirmPassword,
      };
      const response = await voucherRedemptionService.redeemVoucher(data);
      localStorage.setItem("token", response.token);
      setIsAuthenticated(true);
      toast.success("Voucher redeemed successfully! Welcome.");
      setIsVoucherModalOpen(false);
    } catch (error: unknown) {
      const errorMessage = error instanceof Error ? error.message : "Voucher redemption failed.";
      toast.error(errorMessage);
    } finally {
      setIsVoucherSubmitting(false);
    }
  };

  // --------------------------------
  // Login Handlers
  // --------------------------------
  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const loginSuccessful = await loginService.login(username, password);
      if (loginSuccessful) {
        const token = loginService.getToken();
        if (token) {
          console.log("Token stored in LoginService:", token);
        }
        setIsAuthenticated(true);
        setIsModalOpen(false);
        toast.success("Login successful! Welcome back.");
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : "An unknown error occurred.";
      toast.error(errorMessage);
    }
  };

  const handleResetRequestSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    toast.info("Submitting password reset request...");
    try {
      const response = await fetch("/api/user/resetpw", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userName: username }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Error submitting password reset request.");
      }

      toast.success("Password reset request sent! Check your email.");
      setCurrentMode("resetValidation");
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : "An unknown error occurred.";
      if (errorMessage.includes("Too many requests")) {
        toast.error("You’ve requested too many resets. Please wait before trying again.");
      } else {
        toast.error(errorMessage);
      }
    }
  };

  const handleResetValidationSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match. Please try again.");
      return;
    }

    toast.info("Validating reset code and updating password...");
    try {
      const response = await fetch("/api/user/resetpw", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userName: username, resetCode, newPassword }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Error validating reset code.");
      }

      toast.success("Password reset successful! You can now log in with your new password.");
      setCurrentMode("login");
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : "An unknown error occurred.";
      if (errorMessage.includes("Invalid reset code")) {
        toast.error("Invalid reset code. Please check your email and try again.");
      } else if (errorMessage.includes("Reset request expired")) {
        toast.error("The reset code has expired. Please request a new one.");
      } else {
        toast.error(errorMessage);
      }
    }
  };

  // --------------------------------
  // Contact form submission
  // --------------------------------
  const handleContactSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!inquiryType) {
      toast.error("Please select an inquiry type: Request a Demo or General Inquiry.");
      return;
    }

    if (!contactName.trim()) {
      toast.error("Name is required.");
      return;
    }
    if (!contactEmail.trim()) {
      toast.error("Email is required.");
      return;
    }
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(contactEmail)) {
      toast.error("Invalid email format.");
      return;
    }

    if (contactName.length > 100) {
      toast.error("Name cannot exceed 100 characters.");
      return;
    }
    if (contactEmail.length > 100) {
      toast.error("Email cannot exceed 100 characters.");
      return;
    }
    if (contactCompany && contactCompany.length > 100) {
      toast.error("Company name cannot exceed 100 characters.");
      return;
    }
    if (contactNotes && contactNotes.length > 200) {
      toast.error("Notes cannot exceed 200 characters.");
      return;
    }

    setIsSubmitting(true);
    toast.info("Submitting your request...");

    try {
      const altchaField = document.querySelector(
        'input[name="altchaPayload"]'
      ) as HTMLInputElement | null;
      const altchaPayload = altchaField?.value || "";

      const response = await fetch("/api/public/contact", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: contactName,
          email: contactEmail,
          companyName: contactCompany,
          investmentNotes: contactNotes,
          altchaPayload,
          inquiryType: inquiryType === "demo" ? "Request a Demo" : "General Inquiry",
        }),
      });

      const result = await response.json();
      setIsSubmitting(false);

      if (!response.ok) {
        toast.error(result.message || "Error sending contact request.");
        return;
      }

      toast.dismiss();
      toast.success("Thank you! Someone will get back to you soon.");

      // Clear form and close
      setContactName("");
      setContactEmail("");
      setContactCompany("");
      setContactNotes("");
      setInquiryType(null);
      setIsContactModalOpen(false);
    } catch (error) {
      console.error("Error submitting contact request:", error);
      setIsSubmitting(false);
      toast.error("An error occurred while sending contact request.");
    }
  };

  // --------------------------------
  // Render
  // --------------------------------
  return (
    <div className="landing-panel">
      {/* Logo */}
      <img
        src="drivers-logo-for-light-bkg.png"
        alt="Drivers Logo"
        className="landing-logo bounce-in-top"
      />

      {/* If authenticated, show symbol lookup; else show buttons */}
      {isAuthenticated ? (
        <LookupSymbol onSymbol={(symbol) => lookupSymbol(symbol)} />
      ) : (
        <div className="login-contact-btns">
          <button className="login-btn" onClick={() => setIsModalOpen(true)}>
            Login
          </button>
          <button className="contact-btn" onClick={() => setIsContactModalOpen(true)}>
            Contact Us
          </button>
        </div>
      )}

      {/* CONTACT FORM MODAL */}
      {isContactModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setIsContactModalOpen(false)}>
              &times;
            </span>

            {/* Contact Form */}
            <form onSubmit={handleContactSubmit}>
              {/* Button-like inquiry selection */}
              <div className="inquiry-selection">
                <label
                  className={`inquiry-option ${inquiryType === "demo" ? "selected" : ""}`}
                  onClick={() => setInquiryType("demo")}
                >
                  Request a Demo
                </label>
                <label
                  className={`inquiry-option ${inquiryType === "general" ? "selected" : ""}`}
                  onClick={() => setInquiryType("general")}
                >
                  General Inquiry
                </label>
              </div>

              <label htmlFor="contactName">Name (required):</label>
              <input
                type="text"
                id="contactName"
                name="contactName"
                maxLength={100}
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
                disabled={isSubmitting}
                required
              />

              <label htmlFor="contactEmail">Email (required):</label>
              <input
                type="email"
                id="contactEmail"
                name="contactEmail"
                maxLength={100}
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
                disabled={isSubmitting}
                required
              />

              <label htmlFor="contactCompany">Company Name (optional):</label>
              <input
                type="text"
                id="contactCompany"
                name="contactCompany"
                maxLength={100}
                value={contactCompany}
                onChange={(e) => setContactCompany(e.target.value)}
                disabled={isSubmitting}
              />

              <label htmlFor="contactNotes">How can we help you?</label>
              <textarea
                id="contactNotes"
                name="contactNotes"
                placeholder="(optional)"
                maxLength={200}
                value={contactNotes}
                onChange={(e) => setContactNotes(e.target.value)}
                disabled={isSubmitting}
              />

              {/* ALTCHA widget */}
              <altcha-widget
                id="altchaWidget"
                challengeurl="/api/public/altcha-challenge"
                name="altchaPayload"
                auto="onfocus"
                maxnumber="100000"
              ></altcha-widget>

              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      )}

      {/* LOGIN/RESET PASSWORD MODAL */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setIsModalOpen(false)}>
              &times;
            </span>

            {/* LOGIN MODE */}
            {currentMode === "login" && (
              <>
                <form onSubmit={handleLogin}>
                  <label htmlFor="username">Username:</label>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                  <label htmlFor="password">Password:</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <button type="submit">Login</button>
                </form>
                <p className="forgot-password">
                  <a href="#!" onClick={() => setCurrentMode("resetRequest")}>
                    Forgot password?
                  </a>
                </p>

                {/* Link: Have a voucher? */}
                <p className="redeem-voucher">
                  <a
                    href="#!"
                    onClick={() => {
                      setIsModalOpen(false);
                      setIsVoucherModalOpen(true);
                    }}
                  >
                    Have a voucher? Redeem it here
                  </a>
                </p>
              </>
            )}

            {/* PASSWORD RESET REQUEST MODE */}
            {currentMode === "resetRequest" && (
              <>
                <h2>Reset Password</h2>
                <form onSubmit={handleResetRequestSubmit}>
                  <label htmlFor="reset-username">Username:</label>
                  <input
                    type="text"
                    id="reset-username"
                    name="reset-username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                  <button type="submit">Request Reset</button>
                </form>
                <p className="back-to-login">
                  <a href="#!" onClick={() => setCurrentMode("login")}>
                    Back to Login
                  </a>
                </p>
              </>
            )}

            {/* PASSWORD RESET VALIDATION MODE */}
            {currentMode === "resetValidation" && (
              <>
                <h2>Validate Reset Code</h2>
                <form onSubmit={handleResetValidationSubmit}>
                  <label htmlFor="reset-code">Reset Code:</label>
                  <input
                    type="text"
                    id="reset-code"
                    name="reset-code"
                    value={resetCode}
                    onChange={(e) => setResetCode(e.target.value)}
                    required
                  />
                  <label htmlFor="new-password">New Password:</label>
                  <input
                    type="password"
                    id="new-password"
                    name="new-password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                  <label htmlFor="confirm-password">Confirm Password:</label>
                  <input
                    type="password"
                    id="confirm-password"
                    name="confirm-password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  <button type="submit">Reset</button>
                </form>
                <p className="back-to-login">
                  <a href="#!" onClick={() => setCurrentMode("login")}>
                    Back to Login
                  </a>
                </p>
              </>
            )}
          </div>
        </div>
      )}

      {/* VOUCHER REDEMPTION MODAL */}
      {isVoucherModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setIsVoucherModalOpen(false)}>
              &times;
            </span>
            <form onSubmit={handleVoucherRedemptionSubmit}>
              <label htmlFor="voucherCode">Voucher Code:</label>
              <input
                type="text"
                id="voucherCode"
                name="voucherCode"
                value={voucherCode}
                onChange={(e) => setVoucherCode(e.target.value)}
                required
              />
              <label htmlFor="voucherFirstName">First Name:</label>
              <input
                type="text"
                id="voucherFirstName"
                name="voucherFirstName"
                value={voucherFirstName}
                onChange={(e) => setVoucherFirstName(e.target.value)}
                required
              />
              <label htmlFor="voucherLastName">Last Name:</label>
              <input
                type="text"
                id="voucherLastName"
                name="voucherLastName"
                value={voucherLastName}
                onChange={(e) => setVoucherLastName(e.target.value)}
                required
              />
              <label htmlFor="voucherEmail">Email:</label>
              <input
                type="email"
                id="voucherEmail"
                name="voucherEmail"
                value={voucherEmail}
                onChange={(e) => setVoucherEmail(e.target.value)}
                required
              />
              <label htmlFor="voucherPhoneNumber">Phone Number:</label>
              <input
                type="text"
                id="voucherPhoneNumber"
                name="voucherPhoneNumber"
                value={voucherPhoneNumber}
                onChange={(e) => setVoucherPhoneNumber(e.target.value)}
                required
              />
              <label htmlFor="voucherCompany">Company:</label>
              <input
                type="text"
                id="voucherCompany"
                name="voucherCompany"
                value={voucherCompany}
                onChange={(e) => setVoucherCompany(e.target.value)}
                required
              />
              <label htmlFor="voucherPassword">Password:</label>
              <input
                type="password"
                id="voucherPassword"
                name="voucherPassword"
                value={voucherPassword}
                onChange={(e) => setVoucherPassword(e.target.value)}
                required
              />
              <label htmlFor="voucherConfirmPassword">Confirm Password:</label>
              <input
                type="password"
                id="voucherConfirmPassword"
                name="voucherConfirmPassword"
                value={voucherConfirmPassword}
                onChange={(e) => setVoucherConfirmPassword(e.target.value)}
                required
              />
              <button type="submit" disabled={isVoucherSubmitting}>
                {isVoucherSubmitting ? "Redeeming..." : "Redeem Voucher"}
              </button>
            </form>
          </div>
        </div>
      )}

      <footer className="landing-footer">
        <p>© 2025 Drivers Limited</p>
        <p>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            LinkedIn
          </a>{" "}
          |{" "}
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
            X
          </a>
        </p>
      </footer>
    </div>
  );
};

export default LandingPanel;
