import IssueHistory from "../../entities/issueHistory";
import SymbolSearch from "@/entities/symbolSearch";
import { commodityParser, cryptoParser, forexParser } from "../../parsers/issueParsers";
import IStockPriceService from "./iStockPriceService";

//const ALPHA_VANTAGE_KEY = 'WK3XN7IJKGDKF53B'; // MezzeData free key
const ALPHA_VANTAGE_KEY = "Y1F1T7FY3RQA47BQ"; // Agora paid key
const ALPHA_VANTAGE_URL = `https://www.alphavantage.co/query?apikey=${ALPHA_VANTAGE_KEY}&`;

// Stock history adjusted
const AV_FN_DA_HISTORY = "TIME_SERIES_DAILY_ADJUSTED";
const AV_DA_KEY_MD = "Meta Data";
const AV_DA_KEY_TS = "Time Series (Daily)";
const AV_DA_KEY_MD_SYMBOL = "2. Symbol";
const AV_DA_KEY_MD_REFRESH = "3. Last Refreshed";
const AV_DA_KEY_TS_CLOSE = "5. adjusted close";
const AV_DA_KEY_TS_VOLUME = "6. volume";

// Stock symbol search
const AV_FN_SS_SEARCH = "SYMBOL_SEARCH";
const AV_FN_OVERVIEW = "OVERVIEW";
const AV_SS_KEY_BM = "bestMatches"; // Array
const AV_SS_KEY_BM_SYMBOL = "1. symbol"; // E.g. "TSCO.LON"
const AV_SS_KEY_BM_NAME = "2. name"; // E.g. "Tesco PLC"
const AV_SS_KEY_BM_CLOSE = "6. marketClose"; // E.g. "16:30"
const AV_SS_KEY_BM_TZ = "7. timezone"; // E.g. "UTC+01"
const AV_SS_KEY_OV_DESCR = "Description";
// Other bestMatches fields we're not currently using:
// const AV_SS_KEY_BM_TYPE = "3. type"; // E.g. "Equity"
// const AV_SS_KEY_BM_REGION = "4. region"; // E.g. "United Kingdom"
// const AV_SS_KEY_BM_OPEN = "5. marketOpen"; // E.g. "08:00"
// const AV_SS_KEY_BM_CUR = "8. currency"; // E.g. "GBX"
// const AV_SS_KEY_BM_MATCH = "9. matchScore"; // E.g. "0.7273"

// Forex pair history
const AV_FN_FX_FOREX = "FX_DAILY";
const AV_FX_KEY_MD = "Meta Data";
const AV_FX_KEY_TS = "Time Series FX (Daily)";
const AV_FX_KEY_MD_REFRESH = "5. Last Refreshed";
const AV_FX_KEY_TS_CLOSE = "4. close";

// Crypto history
const AV_FN_CO_CRYPTO = "DIGITAL_CURRENCY_DAILY";
const AV_CO_KEY_MD = "Meta Data";
const AV_CO_KEY_TS = "Time Series (Digital Currency Daily)";
const AV_CO_KEY_MD_REFRESH = "6. Last Refreshed";
const AV_CO_KEY_MD_TZ = "7. Time Zone";
const AV_CO_KEY_TS_CLOSE_FIAT = "4. close";
const AV_CO_KEY_TS_VOLUME = "5. volume";

/**
 * Fetches financial data from from AlphaVantage.
 */
export default class AVStockPriceService implements IStockPriceService {
  /**
   * Queries for historic symbol prices.
   * @param symbol Issue symbol to query
   * @return Array of closing prices
   * @throws Query failed or unexpected results
   */
  async queryHistory(issueOrSymbol: IssueHistory | string): Promise<IssueHistory> {
    // Determine the normalized symbol
    const normSymbol =
      typeof issueOrSymbol === "string"
        ? (issueOrSymbol as string).toUpperCase()
        : (issueOrSymbol as IssueHistory).symbol;
    let res = await this.queryHistoryCommodity(normSymbol);
    res = res || (await this.queryHistoryCrypto(normSymbol));
    res = res || (await this.queryHistoryForex(normSymbol));
    res = res || (await this.queryHistoryStock(normSymbol));
    if (typeof issueOrSymbol === "string") {
      // Return result directly
      return res;
    }

    // Apply result to passed issue
    return new IssueHistory(issueOrSymbol, res.refreshDate, res.daily);
  }

  /**
   * Queries for historic stock prices.
   * @param symbol Stock symbol to query
   * @return Array of closing prices
   * @throws Query failed or unexpected results
   */
  async queryHistoryStock(symbol: string): Promise<IssueHistory> {
    try {
      // Query the service for the symbol
      const query = AVStockPriceService.buildQuery({
        function: AV_FN_DA_HISTORY,
        symbol,
        outputsize: "full",
      });
      const response = await fetch(query);
      if (!response.ok) {
        throw new Error(`Failed to fetch issue ${symbol} history: ${response.statusText}`);
      }

      // Validate the results
      const data = await response.json();
      const meta = data?.[AV_DA_KEY_MD];
      const time = data?.[AV_DA_KEY_TS];
      if (!meta || !time) {
        throw new Error("Invalid or missing time series data");
      }

      // Transform the results
      const refreshDate = meta[AV_DA_KEY_MD_REFRESH];
      const sortedKeys = Object.keys(time).sort();
      const daily = sortedKeys.map((key) => {
        const { [AV_DA_KEY_TS_CLOSE]: close, [AV_DA_KEY_TS_VOLUME]: volume } = time[key];
        return {
          date: new Date(key),
          close: Number(close),
          volume: Number(volume),
          isCrpto: false,
        };
      });

      // Build the results
      return new IssueHistory(meta[AV_DA_KEY_MD_SYMBOL], refreshDate, daily);
    } catch (exc) {
      // Log error
      const err = exc as Error;
      console.error("Failed to query history for issue", symbol, err.stack);
      throw err;
    }
  }

  /**
   * Queries for historic Forex currency pair exchange rates.
   * @param symbol Currency pair symbol to query
   * @return Array of exchage rates
   * @throws Query failed or unexpected results
   */
  async queryHistoryForex(symbol: string): Promise<IssueHistory | false> {
    // Validate Forex
    const select = forexParser.select(symbol);
    if (!select) {
      // Not Forex
      return false;
    }
    if (!select.isValid) {
      // Invalid Furrency
      throw new Error(`Invalid Forex currency pair symbol "${symbol}"`);
    }

    try {
      // Query the service for the symbol
      const query = AVStockPriceService.buildQuery({
        function: AV_FN_FX_FOREX,
        from_symbol: select.mainSymbol!,
        to_symbol: select.pairSymbol!,
        outputsize: "full",
      });
      const response = await fetch(query);
      if (!response.ok) {
        throw new Error(`Failed to fetch Forex pair ${symbol} history: ${response.statusText}`);
      }

      // Validate the results
      const data = await response.json();
      const meta = data?.[AV_FX_KEY_MD];
      const time = data?.[AV_FX_KEY_TS];
      if (!meta || !time) {
        throw new Error("Invalid or missing time series data");
      }

      // Transform the results
      const refreshDate = meta[AV_FX_KEY_MD_REFRESH];
      const sortedKeys = Object.keys(time).sort();
      const daily = sortedKeys.map((key) => {
        const { [AV_FX_KEY_TS_CLOSE]: close } = time[key];
        return {
          date: new Date(key),
          close: Number(close),
          volume: 0,
          isCrypto: false,
        };
      });

      // Build the results
      return new IssueHistory(symbol, refreshDate, daily, select.name);
    } catch (exc) {
      // Log error
      const err = exc as Error;
      console.error("Failed to query history for Forex pair", symbol, err.stack);
      throw err;
    }
  }

  /**
   * Queries for historic crypto/fiat currency pair price.
   * @param symbol Currency pair symbol to query
   * @return Array of exchage rates
   * @throws Query failed or unexpected results
   */
  async queryHistoryCrypto(symbol: string): Promise<IssueHistory | false> {
    // Validate Crypto
    const select = cryptoParser.select(symbol);
    if (!select) {
      // Not crypto
      return false;
    }
    if (!select.isValid) {
      // Invalid Crypto
      throw new Error(`Invalid crypto fiat symbol pair "${symbol}"`);
    }

    try {
      // Query the service for the symbol pair
      const query = AVStockPriceService.buildQuery({
        function: AV_FN_CO_CRYPTO,
        symbol: select.mainSymbol!,
        market: select.pairSymbol!,
      });
      const response = await fetch(query);
      if (!response.ok) {
        throw new Error(`Failed to fetch crypto ${symbol} history: ${response.statusText}`);
      }

      // Validate the results
      const data = await response.json();
      const meta = data?.[AV_CO_KEY_MD];
      const time = data?.[AV_CO_KEY_TS];
      if (!meta || !time) {
        throw new Error("Invalid or missing time series data");
      }

      // Transform the results
      const refreshDate = `${meta[AV_CO_KEY_MD_REFRESH]} ${meta[AV_CO_KEY_MD_TZ]}`;
      const sortedKeys = Object.keys(time).sort();
      const daily = sortedKeys.map((key) => {
        const { [AV_CO_KEY_TS_CLOSE_FIAT]: close, [AV_CO_KEY_TS_VOLUME]: volume } = time[key];
        return {
          date: new Date(key),
          close: Number(close),
          volume: Number(volume),
          isCrypto: true,
        };
      });

      // Build the results
      return new IssueHistory(select.symbol, refreshDate, daily);
    } catch (exc) {
      // Log error
      const err = exc as Error;
      console.error("Failed to query history for crypto fiat pair ", symbol, err.stack);
      throw err;
    }
  }

  /**
   * Queries for historic crypto/fiat currency pair price.
   * @param symbol Currency pair symbol to query
   * @return Array of exchage rates
   * @throws Query failed or unexpected results
   */
  async queryHistoryCommodity(symbol: string): Promise<IssueHistory | false> {
    const symbolToFn: { [symbol: string]: { function: string; frequency: string } } = {
      WTI: { function: "WTI", frequency: "daily" },
      BRT: { function: "BRENT", frequency: "daily" },
      GAS: { function: "NATURAL_GAS", frequency: "daily" },
      CU: { function: "COPPER", frequency: "monthly" },
      AL: { function: "ALUMINUM", frequency: "monthly" },
      WHT: { function: "WHEAT", frequency: "monthly" },
      CRN: { function: "CORN", frequency: "monthly" },
      CTN: { function: "COTTON", frequency: "monthly" },
      SGR: { function: "SUGAR", frequency: "monthly" },
      CFE: { function: "COFFEE", frequency: "monthly" },
      GCI: { function: "ALL_COMMODITIES", frequency: "monthly" },
    };

    // Validate Commodity
    const select = commodityParser.select(symbol);
    if (!select) {
      // Not commmodity
      return false;
    }
    const fn = symbolToFn[select.mainSymbol!];
    if (!select.isValid || !fn) {
      // Invalid Currency
      throw new Error(`Invalid commodity symbol "${symbol}"`);
    }

    try {
      // Query the service for the symbol
      const query = AVStockPriceService.buildQuery({
        function: fn.function,
        interval: fn.frequency,
      });
      const response = await fetch(query);
      if (!response.ok) {
        throw new Error(`Failed to fetch commodity ${symbol} history: ${response.statusText}`);
      }

      // Validate the results
      const data = await response.json();
      const time = data?.["data"];
      if (
        !time ||
        !Array.isArray(time) ||
        time.length === 0 ||
        !Object.prototype.hasOwnProperty.call(time[0], "date") ||
        !Object.prototype.hasOwnProperty.call(time[0], "value")
      ) {
        throw new Error("Invalid or missing data");
      }

      // Transform the results
      const refreshDate = time[0].date;
      const daily = time
        .reverse()
        .map((timeEntry) => ({
          date: new Date(timeEntry.date),
          close: Number(timeEntry.value),
          volume: 0,
          isCrypto: false,
        }))
        .filter((day) => !isNaN(day.close));

      // Build the results
      return new IssueHistory(symbol, refreshDate, daily, select.name);
    } catch (exc) {
      // Log error
      const err = exc as Error;
      console.error("Failed to query history for commodity", symbol, err.stack);
      throw err;
    }
  }

  /**
   * Populates symbol details.
   * TODO: Since these are generated internally for commodities, crypto and forex should we move those elsewhere?
   * @param symbol Issue symbol to query
   * @param name Optionally override the name
   * @return Issue history with just the metadata filled out, no actual historical data
   * @throws Query failed, invalid symbol or unexpected results
   */
  async queryIssue(symbol: string, name?: string): Promise<IssueHistory> {
    const normSymbol = symbol.toUpperCase();
    let res = this.queryIssueCommodity(normSymbol, name);
    res = res || this.queryIssueCrypto(normSymbol, name);
    res = res || this.queryIssueForex(normSymbol, name);
    res = res || (await this.queryIssueStock(normSymbol, name));
    return res;
  }

  /**
   * Populates synthesized commodity symbol details, if the symbol is commodity.
   *
   * @param symbol Issue symbol to query
   * @param name Optionally override the name
   * @return Issue history with just the metadata filled out, or false if not commodity
   * @throws Query failed or unexpected results
   */
  private queryIssueCommodity(symbol: string, name?: string): IssueHistory | false {
    // Validate commodity
    const select = commodityParser.select(symbol);
    if (!select) {
      // Not commodity
      return false;
    }
    if (!select.isValid) {
      // Invalid Forex
      throw new Error(`Invalid commodity symbol "${symbol}"`);
    }

    // Synthesize issue details
    name = name ?? select.name ?? select.symbol;
    return new IssueHistory({
      symbol,
      name,
      closeTime: "00:00",
      timeZone: "UTC",
      isCrypto: false,
    });
  }

  /**
   * Populates synthesized crypto symbol details, if the symbol is crypto.
   *
   * @param symbol Issue symbol to query
   * @param name Optionally override the name
   * @return Issue history with just the metadata filled out, or false if not crypto
   * @throws Query failed or unexpected results
   */
  private queryIssueCrypto(symbol: string, name?: string): IssueHistory | false {
    // Validate crypto
    const select = cryptoParser.select(symbol);
    if (!select) {
      // Not crypto
      return false;
    }
    if (!select.isValid) {
      // Invalid crypto
      throw new Error(`Invalid crypto fiat pair symbol "${symbol}"`);
    }

    // Synthesize issue details
    name = name ?? select.name ?? select.symbol;
    return new IssueHistory({
      symbol,
      name,
      closeTime: "00:00",
      timeZone: "GMT",
      isCrypto: true,
    });
  }

  /**
   * Populates synthesized Forex symbol details, if the symbol is Forex.
   *
   * @param symbol Issue symbol to query
   * @param name Optionally override the name
   * @return Issue history with just the metadata filled out, or false if not Forex
   * @throws Query failed or unexpected results
   */
  private queryIssueForex(symbol: string, name?: string): IssueHistory | false {
    // Validate Forex
    const select = forexParser.select(symbol);
    if (!select) {
      // Not Forex
      return false;
    }
    if (!select.isValid) {
      // Invalid Forex
      throw new Error(`Invalid currency pair symbol "${symbol}"`);
    }

    // Synthesize issue details
    name = name ?? select.name ?? select.symbol;
    return new IssueHistory({
      symbol,
      name,
      closeTime: "00:00",
      timeZone: "UTC",
      isCrypto: false,
    });
  }

  /**
   * Search for stock symbol details. Note that this is really uses a matching function,
   * so we only want to turn the top result with very high confidence. We could use
   * the same underlying API to provide a symbol lookup in a different method here.
   *
   * @param symbol Issue symbol to query
   * @param name Optionally override the name
   * @return Issue history with just the metadata filled out, no actual historical data
   * @throws Query failed or unexpected results
   */
  private async queryIssueStock(symbol: string, name?: string): Promise<IssueHistory> {
    try {
      // Query the overview for the issue
      const overQuery = AVStockPriceService.buildQuery({
        function: AV_FN_OVERVIEW,
        symbol: symbol,
      });
      const overRes = await fetch(overQuery);
      if (!overRes.ok) {
        throw new Error(`Failed to fetch issue ${symbol} overview: ${overRes.statusText}`);
      }
      const overData = await overRes.json();

      // Query the search service for the issue
      // Note: search service has market close time and time zone missing from description
      const searchQuery = AVStockPriceService.buildQuery({
        function: AV_FN_SS_SEARCH,
        keywords: symbol,
      });
      const searchRes = await fetch(searchQuery);
      if (!searchRes.ok) {
        throw new Error(`Failed to fetch issue ${symbol} details: ${searchRes.statusText}`);
      }

      // Validate the results
      const searchData = await searchRes.json();
      const matches = searchData?.[AV_SS_KEY_BM];
      if (!Array.isArray(matches)) {
        throw new Error("Invalid or missing symbol matches");
      }
      if (matches.length === 0) {
        throw new Error(`Invalid unable to locate symbol "${symbol}"`);
      }

      // Make sure the results adequately match
      const match = matches[0];
      const matchSymbol = (match[AV_SS_KEY_BM_SYMBOL] as string).toUpperCase();
      if (symbol !== matchSymbol) {
        // Check for close enough
        if (!matchSymbol.startsWith(symbol) || matchSymbol[symbol.length] !== ".") {
          // No match
          throw new Error(`Invalid unable to locate symbol "${symbol}"`);
        }
      }

      // Transform the results
      return new IssueHistory({
        symbol,
        name: name ?? (match[AV_SS_KEY_BM_NAME] as string),
        description: overData[AV_SS_KEY_OV_DESCR] as string,
        closeTime: match[AV_SS_KEY_BM_CLOSE] as string,
        timeZone: match[AV_SS_KEY_BM_TZ] as string,
        isCrypto: false,
      });
    } catch (exc) {
      // Log error
      const err = exc as Error;
      console.error("Failed to query details for issue", symbol, err.stack);
      throw err;
    }
  }

  /**
   * Searches for equity stock symbol matches against the data provider service.
   * @param symbol Symbol or partial symbol to match for equity asset
   * @returns Match results
   */
  async searchStockSymbol(symbol: string): Promise<SymbolSearch | null> {
    const normSymbol = symbol.toUpperCase();
    try {
      // Query the service for the issue
      const query = AVStockPriceService.buildQuery({
        function: AV_FN_SS_SEARCH,
        keywords: normSymbol,
      });
      const response = await fetch(query);
      if (!response.ok) {
        console.error(`Failed to fetch issue ${normSymbol} details: ${response.statusText}`);
        return null;
      }

      // Validate the results
      const data = await response.json();
      const matches = data?.[AV_SS_KEY_BM];
      if (!Array.isArray(matches)) {
        console.error("Invalid or missing symbol matches");
        return null;
      }

      // Transform the results
      return {
        searchText: symbol,
        results: matches.slice(0, 5).map((match) => {
          return {
            symbol: match[AV_SS_KEY_BM_SYMBOL],
            name: match[AV_SS_KEY_BM_NAME],
          };
        }),
      };
    } catch (exc) {
      // Log error
      const err = exc as Error;
      console.error("Failed to query history for issue", normSymbol, err.stack);
      return null;
    }
  }

  /**
   * Builds query string from params.
   * @param params Params to build
   * @returns Built query string
   */
  private static buildQuery(params: { [key: string]: string }): string {
    return (
      ALPHA_VANTAGE_URL +
      Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join("&")
    );
  }
}
