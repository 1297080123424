import React, { Fragment } from "react";
import ModelIndicator from "@/services/modelIndicator";
import ChromeService from "@/services/chromeService";
import { Bar } from "react-chartjs-2";
import { IndicatorPeriod } from "../assetDashboard.types";
import ModelReport from "@/services/modelReport";
import { ScriptableContext } from "chart.js";

/** Specifies the market performance chart's properties. */
type MarketPerformanceChartProps = {
  period: IndicatorPeriod;
  indicator: ModelIndicator;
  report: ModelReport;
};

/**
 * Displays the market performance chart.
 * @param props Chart properties
 * @returns Chart root element
 */
export default function MarketPerformanceChart(
  props: MarketPerformanceChartProps
): React.ReactElement {
  const { report } = props;

  // Determine chart colors for market bars
  const marketColor = (ctx: ScriptableContext<"bar">): string => {
    const value = ctx.raw as number; // Explicitly cast raw to number
    return value < 0
      ? ChromeService.graphColor.marketNegative
      : value > 0
        ? ChromeService.graphColor.marketPositive
        : ChromeService.graphColor.neutral;
  };

  // Determine chart colors for model bars
  const modelColor = (ctx: ScriptableContext<"bar">): string => {
    const value = ctx.raw as number;
    return value < 0
      ? ChromeService.graphColor.negative
      : value > 0
        ? ChromeService.graphColor.positive
        : ChromeService.graphColor.neutral;
  };

  // Deterine colors on pivot chart
  const pivotColor = (ctx: ScriptableContext<"bar">): string => {
    return ctx.dataIndex === 1 ? modelColor(ctx) : marketColor(ctx);
  };

  // Determine the pivot chart properties
  const overallData = {
    labels: ["Market Performance", "Model Performance"],
    datasets: [
      {
        label: "Performance",
        data: [report.summary.marketPct * 100, report.summary.modelPct * 100],
        backgroundColor: pivotColor,
      },
    ],
  };
  const overallOptions = {
    indexAxis: "y" as const,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  // Determine the period chart properties
  const periodData =
    report.summaryBreakdown.length === 0
      ? undefined
      : {
          labels: report.summaryBreakdown.map((rep) => rep.label),
          datasets: [
            {
              label: "Market Performance",
              data: report.summaryBreakdown.map((rep) => rep.marketPct * 100),
              backgroundColor: marketColor,
            },
            {
              label: "Model Performance",
              data: report.summaryBreakdown.map((rep) => rep.modelPct * 100),
              backgroundColor: modelColor,
            },
          ],
        };
  const periodOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  if (!periodData) {
    // No period data
    return (
      <div
        style={{
          position: "absolute",
          inset: "4pt",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Bar data={overallData} options={overallOptions} />
      </div>
    );
  }
  return (
    <Fragment>
      <div
        style={{
          position: "absolute",
          inset: "4pt",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "50%",
        }}
      >
        <Bar data={overallData} options={overallOptions} />
      </div>
      <div
        style={{
          position: "absolute",
          inset: "4pt",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "50%",
          top: "50%",
        }}
      >
        <Bar data={periodData} options={periodOptions} />
      </div>
    </Fragment>
  );
}
