import React, { Fragment } from "react";
import "./marketSentimentDetails.css";
import ChromeService from "@/services/chromeService";

const heatmapRanges = [
  { label: "Strong Buy", range: [11, 15] },
  { label: "Medium Buy", range: [6, 10] },
  { label: "Weak Buy", range: [1, 5] },
  { label: "Neutral", range: [0, 0] },
  { label: "Weak Sell", range: [-5, -1] },
  { label: "Medium Sell", range: [-10, -6] },
  { label: "Strong Sell", range: [-15, -11] },
];

const HeatmapUtils = {
  getHeatmapColor(level: number | null): string {
    return ChromeService.getHeatmapColor(level);
  },
};

const HeatmapKey: React.FC = () => {
  return (
    <div className="heatmap-key-container">
      <h3 className="heatmap-key-title">Chart Key</h3>
      <div className="heatmap-key">
        {heatmapRanges.map(({ label, range }) => {
          const color = HeatmapUtils.getHeatmapColor(range[0]);
          return (
            <Fragment key={label}>
              <div className="heatmap-key-color" style={{ backgroundColor: color }} />
              <div className="heatmap-key-label">{label}</div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default HeatmapKey;
