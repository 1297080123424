import IssueHistory from "@/entities/issueHistory";

export default class DashboardService {
  /**
   * Fetches symbol details and history from the server in one call.
   * @param symbol The symbol to fetch (e.g., AAPL)
   * @returns The IssueHistory object or an error message
   */
  static async getSymbol(symbol: string): Promise<IssueHistory | string> {
    try {
      const response = await fetch(`/api/assets/symbol/${symbol}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch symbol details: ${response.statusText}`);
      }

      const issueHistory: IssueHistory = await response.json();

      // Ensure `daily` exists and transform if defined
      const transformedDaily =
        issueHistory.daily?.map((entry) => ({
          ...entry,
          date: new Date(entry.date), // Convert date string to Date object
        })) ?? []; // Fallback to an empty array if `daily` is undefined

      // Create a new IssueHistory instance with the transformed `daily`
      const transformedIssueHistory = new IssueHistory(
        issueHistory.symbol,
        issueHistory.refreshDate,
        transformedDaily,
        issueHistory.name
      );

      return transformedIssueHistory;
    } catch (error: unknown) {
      if (error instanceof Error) {
        console.error(`Error fetching symbol data for ${symbol}:`, error.message);
      } else {
        console.error(`Error fetching symbol data for ${symbol}:`, error);
      }
      return `Unable to fetch symbol data for ${symbol}`;
    }
  }
}
