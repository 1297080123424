import IssueHistory from "../../entities/issueHistory";
import SymbolSearch from "@/entities/symbolSearch";
import IStockPriceService from "./iStockPriceService";
import AVStockPriceService from "./avStockPriceService";
import EODHDStockPriceService from "./eodhdStockPriceService";

/**
 * Fetches financial data, prioritizing EODHD with AlphaVantage as fallback.
 */
export default class StockPriceService implements IStockPriceService {
  private eodhdService: EODHDStockPriceService;
  private avService: AVStockPriceService;

  constructor() {
    this.eodhdService = new EODHDStockPriceService();
    this.avService = new AVStockPriceService();
  }

  async queryHistory(issueOrSymbol: IssueHistory | string): Promise<IssueHistory> {
    try {
      // Attempt to fetch from EODHD
      const issueHistory = await this.eodhdService.queryHistory(issueOrSymbol);

      // Validate the fetched data
      if (!Array.isArray(issueHistory.daily)) {
        throw new Error("EODHD returned invalid data: Expected 'daily' to be an array.");
      }

      issueHistory.daily.forEach((entry, index) => {
        if (!entry.date || typeof entry.close !== "number") {
          throw new Error(
            `Invalid data format in 'daily' at index ${index}: Missing 'date' or 'close'.`
          );
        }
      });

      return issueHistory;
    } catch (error) {
      console.warn("EODHD failed, falling back to AlphaVantage:", error);
      // Fallback to AlphaVantage
      return this.avService.queryHistory(issueOrSymbol);
    }
  }

  async queryIssue(symbol: string, name?: string): Promise<IssueHistory> {
    try {
      // Attempt to fetch from EODHD
      const issueHistory = await this.eodhdService.queryIssue(symbol, name);
      console.log("EODHD IssueHistory returned:", issueHistory); // Log the IssueHistory from EODHD
      return issueHistory;
    } catch (error) {
      console.warn("EODHD failed, falling back to AlphaVantage:", error);

      // Fallback to AlphaVantage
      const issueHistory = await this.avService.queryIssue(symbol, name);
      console.log("AlphaVantage IssueHistory returned:", issueHistory); // Log the IssueHistory from AV
      return issueHistory;
    }
  }

  async searchStockSymbol(symbol: string): Promise<SymbolSearch | null> {
    try {
      // Attempt to fetch from EODHD
      const result = await this.eodhdService.searchStockSymbol(symbol);
      if (result && result.results.length > 0) {
        return result;
      } else {
        console.warn("EODHD returned no results, falling back to AlphaVantage.");
      }
    } catch (error) {
      console.warn("EODHD failed, falling back to AlphaVantage:", error);
    }

    // Fallback to AlphaVantage
    try {
      return await this.avService.searchStockSymbol(symbol);
    } catch (error) {
      console.error("AlphaVantage also failed:", error);
      return null;
    }
  }
}
