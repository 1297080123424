import React from "react";
import "./lookupSymbol.css";
import SymbolSearch from "@/entities/symbolSearch";
import { issueParsers } from "@/parsers/issueParsers/issueParsers.ts";
import IssueHistoryService from "@/services/issueHistoryService";
import IssueCacheService from "@/services/issueCacheService";

type LookupSymbolProps = {
  onSymbol: (symbol: string) => void;
};

/**
 * Presents the interface to lookup a symbol.
 * @param props Properties from parent
 * @returns Lookup symbol element
 */
export default function LookupSymbol(props: LookupSymbolProps): React.ReactElement {
  const symbolRef = React.useRef<HTMLInputElement>(null);
  const [symbolInput, setSymbolInput] = React.useState("");
  const [searchResults, setSearchResults] = React.useState<SymbolSearch | null>(null);
  let symbolTimeout: NodeJS.Timeout | null;

  /**
   * Notifies that a symbol has been selected.
   * @param symbol Selected symbol
   */
  const selectSymbol = (symbol: string) => {
    props.onSymbol(symbol);
    setSymbolInput("");
    setSearchResults(null);
  };

  /**
   * Handles change of input symbol.
   * @param changed Changed value
   * @param updateNow Indicates search should be updated right away
   */
  const onInputChange = (changed: string, updateNow?: boolean) => {
    setSymbolInput(changed);
    setSearchResults(null);

    if (!!symbolTimeout) {
      clearTimeout(symbolTimeout);
    }

    // Wait to make sure the user is done typing
    symbolTimeout = !!changed.length ? setTimeout(onSearchSymbol, !updateNow ? 1000 : 1) : null;
  };

  /**
   * Handles keyboard input looking for Enter.
   * @param event Keyboard input event details
   */
  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && symbolInput.length > 0) {
      selectSymbol(symbolInput);
    }
  };

  /**
   * Performs symbol search when the user stops typing.
   */
  const onSearchSymbol = async () => {
    const searchInput = symbolRef.current?.value ?? "";

    if (!searchInput.length) {
      setSearchResults(null);
      return;
    }

    if (!navigator.onLine) {
      // Offline mode
      const cacheResults = IssueCacheService.searchLocalCache(searchInput);
      if (cacheResults) {
        setSearchResults(cacheResults);
      } else {
        setSearchResults({ searchText: searchInput, results: [] });
      }
      return;
    }

    // Online mode: Use IssueHistoryService
    IssueHistoryService.searchSymbol(searchInput).then((res) => {
      if (typeof res === "string") {
        console.error("Error from searchSymbol:", res);
        setSearchResults({ searchText: searchInput, results: [] });
      } else if (res) {
        setSearchResults(res);
      } else {
        setSearchResults({ searchText: searchInput, results: [] });
      }
    });
  };

  /**
   * Handles selection from search results.
   * @param selected Selected from search results
   */
  const onSelectSearch = (selected: string) => {
    const lookup = issueParsers.select(selected);
    if (lookup && !lookup.isComplete) {
      onInputChange(lookup.symbol, true);
    } else {
      // Fetch history for the symbol
      selectSymbol(selected);
    }
  };

  /**
   * Renders the symbol search results, if any.
   * @returns Symbol search results nodes
   */
  const renderSearchResults = (): React.ReactNode => {
    if (!searchResults) {
      return null;
    }

    if (searchResults.results.length === 0) {
      return (
        <div className="drivers-lookup-list">
          <span>No matches for symbol '{searchResults.searchText}'</span>
        </div>
      );
    }

    return (
      <div className="drivers-lookup-list">
        {searchResults.results.map((result) => (
          <React.Fragment key={result.symbol}>
            <span onClick={() => onSelectSearch(result.symbol)}>{result.symbol}</span>
            <span onClick={() => onSelectSearch(result.symbol)}>{result.name}</span>
          </React.Fragment>
        ))}
      </div>
    );
  };

  // Returns the lookup markup
  return (
    <div className="drivers-lookup">
      {/* Removed the label to keep the search bar only */}
      <div className="drivers-lookup-popup">
        <input
          ref={symbolRef}
          type="text"
          title="Symbol"
          value={symbolInput}
          onChange={(e) => onInputChange(e.target.value)}
          onKeyDown={(e) => onInputKeyDown(e)}
          placeholder="Type a symbol..."
        />
        {renderSearchResults()}
      </div>

      {/* Magnifying-glass icon; disable if input is empty */}
      <span
        className={"material-icons" + (symbolInput.length === 0 ? " lookup-disabled" : "")}
        onClick={() => selectSymbol(symbolInput)}
      >
        search
      </span>
    </div>
  );
}
