import StockPriceService from "./stockprice/stockPriceService";
import IssueHistory from "@/entities/issueHistory"; // Adjust based on your project structure
import SymbolSearch from "@/entities/symbolSearch";
import { issueParsers } from "@/parsers/issueParsers/issueParsers";
export default class IssueHistoryService {
  private static stockPriceService = new StockPriceService();
  /**
   * Loads the issue details and history directly from the service.
   * Skipping cache logic since it's out of scope for now.
   *
   * @param symbol - The symbol to fetch details for (e.g., AAPL)
   * @param name - Optional override for the name
   * @returns - Loaded issue history or error message
   */
  static async loadIssue(symbol: string, name?: string): Promise<IssueHistory | string> {
    try {
      // Load the issue details from the service
      const issue = await this.stockPriceService.queryIssue(symbol, name);
      if (!issue) {
        return `Unable to Lookup symbol "${symbol.toUpperCase()}"`;
      }

      // Load the current history for the symbol
      const updatedIssue = await this.stockPriceService.queryHistory(issue);

      // Return the fetched issue details and history
      return updatedIssue;
    } catch (err) {
      console.error(`Error fetching issue for symbol "${symbol}":`, err);
      return `Unable to load symbol "${symbol.toUpperCase()}"`;
    }
  }

  /**
   * Searches for a symbol based on search text.
   * @param searchText The text to search symbols by
   * @returns The search results or an error message
   */
  static async searchSymbol(searchText: string): Promise<SymbolSearch | false> {
    try {
      // First, try local results (commodities, forex, crypto)
      const localResults = issueParsers.search(searchText);

      // If we have local results, return them
      if (localResults) {
        return localResults;
      }

      // Fallback to StockPriceService for remote search
      const remoteResults: SymbolSearch | null =
        await this.stockPriceService.searchStockSymbol(searchText);

      // Return remote results or false if both fail
      return remoteResults ?? false;
    } catch (err) {
      console.error(`Error searching for symbol with text "${searchText}":`, err);
      return false; // Return false if there's a search error
    }
  }
}
