import React, { Fragment } from "react";
import ModelIndicator from "@/services/modelIndicator";
import ChromeService from "@/services/chromeService";
import { Bar, Line } from "react-chartjs-2";
import { ChartOption, IndicatorPeriod } from "../assetDashboard.types";
import ModelReport from "@/services/modelReport";
import { ScriptableContext } from "chart.js";

/** Specifies the risk performance chart's properties. */
type RiskPerformanceChartProps = {
  period: IndicatorPeriod;
  indicator: ModelIndicator;
  chartOptions?: ChartOption[];
  report: ModelReport;
};

/**
 * Displays the risk performance chart.
 * @param props Chart properties
 * @returns Chart root element
 */
export default function RiskPerformanceChart(props: RiskPerformanceChartProps): React.ReactElement {
  const { report } = props;

  // Determine chart colors for market bars
  const marketColor = (ctx: ScriptableContext<"bar">): string => {
    const value: number = ctx.raw as number;
    return value < 0
      ? ChromeService.graphColor.marketNegative
      : value > 0
        ? ChromeService.graphColor.marketPositive
        : ChromeService.graphColor.neutral;
  };

  // Determine chart colors for model bars
  const modelColor = (ctx: ScriptableContext<"bar">): string => {
    const value: number = ctx.raw as number;
    return value < 0
      ? ChromeService.graphColor.negative
      : value > 0
        ? ChromeService.graphColor.positive
        : ChromeService.graphColor.neutral;
  };

  // Deterine colors on pivot chart
  const pivotColor = (ctx: ScriptableContext<"bar">): string => {
    console.debug(ctx);
    return ctx.dataIndex === 1 ? modelColor(ctx) : marketColor(ctx);
  };

  // Determine the pivot chart properties
  const overallData = {
    labels: ["Market Risk", "Model Risk"],
    datasets: [
      {
        label: "Performance",
        data: [report.summary.marketRisk * 100, report.summary.modelRisk * 100],
        backgroundColor: pivotColor,
      },
    ],
  };
  const overallOptions = {
    indexAxis: "y" as const,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  // Determine the period chart properties
  const periodData =
    report.summaryBreakdown.length === 0
      ? undefined
      : {
          labels: report.summaryBreakdown.map((rep) => rep.label),
          datasets: [
            {
              label: "Market Risk",
              data: report.summaryBreakdown.map((rep) => rep.marketRisk * 100),
              backgroundColor: marketColor,
            },
            {
              label: "Model Risk",
              data: report.summaryBreakdown.map((rep) => rep.modelRisk * 100),
              backgroundColor: modelColor,
            },
          ],
        };
  const periodOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  // TODO Closing price is a temporary placeholder until we get a better market risk calculation
  // TODO While this code is derived from currentPositionChart, when we adjust the periods
  //    this will actually be more correct and probably needs to be backfilled.
  const clsData = props.indicator.closeValue.filter((value, index) => {
    const date = props.indicator.closeDate[index];
    return date >= report.startDate && date <= report.endDate;
  });

  const labels = clsData.map(() => "");
  const closePriceData = {
    labels,
    datasets: [
      {
        label: props.indicator.period + " Close",
        data: clsData,
        segment: {
          borderColor: "gray",
        },
      },
    ],
  };
  const closePriceOptions = {
    responsive: true,
    maintainAspectRatio: false,
    datasets: {
      line: {
        borderColor: "#888",
        pointRadius: 0,
        borderWidth: 1,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        grid: { display: false },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const showPrice = props.chartOptions?.find((opt) => opt.id === "rpprice")?.isEnabled ?? false;

  if (!periodData) {
    // No period data
    // TODO This should not happen. But we don't want to just show closing price here.
    // TODO Also we need to keep the overall chart logic to restore when we've fixed the market calculation,
    //    so we can restore it to the dual chart.
    return (
      <div
        style={{
          position: "absolute",
          inset: "4pt",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Bar data={overallData} options={overallOptions} />
      </div>
    );
  }

  if (!showPrice) {
    // Show period data only, no price
    return (
      <div
        style={{
          position: "absolute",
          inset: "4pt",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Bar data={periodData} options={periodOptions} />
      </div>
    );
  }

  // Show both charts
  return (
    <Fragment>
      <div
        style={{
          position: "absolute",
          inset: "4pt",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "50%",
        }}
      >
        <Line options={closePriceOptions} data={closePriceData} />
      </div>
      <div
        style={{
          position: "absolute",
          inset: "4pt",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "50%",
          top: "50%",
        }}
      >
        <Bar data={periodData} options={periodOptions} />
      </div>
    </Fragment>
  );
}

const priceOption: ChartOption = { chart: "rskprf", id: "rpprice", name: "Price", isEnabled: true };

export function getDefaultRiskPerformanceOptions(): ChartOption[] {
  return [priceOption];
}
