import React from "react";
import { PageTabBarTab } from "../pageTabBar";
import CapitalExposureChart from "./capitalExposureChart";
import CapitalGrowthChart, { getDefaultCapitalGrowthOptions } from "./capitalGrowthChart";
import ModelIndicator from "@/services/modelIndicator";
import IssueHistory from "@/entities/issueHistory";
import { ChartOption, IndicatorPeriod } from "../assetDashboard.types";
import MarketPerformanceChart from "./marketPerformanceChart";
import MarketSentimentChart from "./marketSentimentChart";
import RiskPerformanceChart, { getDefaultRiskPerformanceOptions } from "./riskPerformanceChart";
import PrintableDocument from "../printableDocument";
import { IndicatorStrategy } from "@/entities/indicatorStrategy.ts";
import ModelReport from "@/services/modelReport";

type ChartPanelProps = {
  tab: PageTabBarTab;
  period: IndicatorPeriod;
  displayIssue?: IssueHistory;
  strategy: IndicatorStrategy;
  indicators?: ModelIndicator[];
  chartOptions?: ChartOption[];
  setChartOption: (options: ChartOption[]) => void;
  report: ModelReport | null;
};

/**
 * Displays the current chart
 * @param props Chart properties
 * @returns Chart panel root element
 */
export default function ChartPanel(props: ChartPanelProps): React.ReactElement {
  console.log("ChartPanel props.displayIssue:", props.displayIssue);

  /** Renders panel with a simple message. */
  const renderMessagePanel = (msg: string) => {
    return (
      <div style={{ position: "relative", padding: "8pt" }}>
        <img
          src="drivers-logo-for-light-bkg.png"
          alt="Drivers Insight"
          style={{ width: "100%", height: "auto" }}
        />
        {msg}
      </div>
    );
  };

  // Handle edge cases
  if (!props.displayIssue) {
    return renderMessagePanel("Please look up a symbol to chart, above.");
  }
  const indicator = props.indicators?.find((test) => test.period === props.strategy);
  if (!indicator) {
    return renderMessagePanel("Please select an investment strategy below.");
  }

  if (!props.report) {
    return renderMessagePanel("Report data is not available.");
  }

  // Select the correct panel to render
  switch (props.tab.tabId) {
    case "capgro":
      return (
        <CapitalGrowthChart
          period={props.period}
          indicator={indicator}
          strategy={props.strategy}
          chartOptions={props.chartOptions}
          setChartOption={props.setChartOption}
          report={props.report}
        />
      );
    case "capexp":
      return (
        <CapitalExposureChart period={props.period} indicator={indicator} report={props.report} />
      );
    case "marprf":
      return (
        <MarketPerformanceChart period={props.period} indicator={indicator} report={props.report} />
      );
    case "rskprf":
      return (
        <RiskPerformanceChart
          period={props.period}
          indicator={indicator}
          chartOptions={props.chartOptions}
          report={props.report}
        />
      );
    case "marsen":
      return (
        <MarketSentimentChart
          period={props.period}
          indicators={props.indicators!}
          strategy={props.strategy}
        />
      );
    case "print":
      return (
        <PrintableDocument
          period={props.period}
          indicator={indicator}
          strategy={props.strategy}
          issue={props.displayIssue}
          indicators={props.indicators}
          report={props.report}
        />
      );
  }
  console.log("props.displayIssue:", props.displayIssue);
  // Render placeholder
  return (
    <div style={{ position: "relative", top: 0, left: 0, bottom: 0, right: 0, padding: "8pt" }}>
      Placeholder for {props.tab.display} Chart
      {props.displayIssue && ` for ${props.displayIssue.name}`}
    </div>
  );
}

const globalChartOptions: ChartOption[] = [
  { chart: "global", id: "comments", name: "Comments", isEnabled: true },
];

export function getDefaultChartOptions(): ChartOption[] {
  return [
    ...getDefaultCapitalGrowthOptions(),
    ...getDefaultRiskPerformanceOptions(),
    ...globalChartOptions,
  ];
}

export function getChartOptionsFor(tab: PageTabBarTab, allOptions: ChartOption[]): ChartOption[] {
  return allOptions.filter((option) => option.chart === tab.tabId || option.chart === "global");
}
